import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { firestore } from '../firebase'
import { Link as RouterLink } from "react-router-dom"
import Link from '@material-ui/core/Link'
import LinearProgress from '@material-ui/core/LinearProgress'
import { loadAnimation } from 'lottie-web'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'

function Journey(props) {
  //TODO: load own progress!
    const { classes, match } = props
    const [journey, setJourney] = useState(null)
    const [ extraInfo, setExtraInfo ] = useState(null)
    const [ currentStep, setCurrentStep ] = useState(0)
    const [ animationDocument, setAnimationDocument ] = useState(null)
    const [ animationObject, setAnimationObject ] = useState(null)
    const [ numFrames, setNumFrames ] = useState(null)
    const [ allLoaded, setAllLoaded ] = useState(null)
    const lottieEL = useRef(null)

    const advanceStep = () => {
      console.log('yo', currentStep, animationDocument.steps)
      let fromValue, toValue
      if (currentStep < animationDocument.steps.length - 1) {
        fromValue = animationDocument.steps[currentStep] * numFrames
        toValue = animationDocument.steps[currentStep+1] * numFrames
      } else {
        fromValue = animationDocument.steps[currentStep] * numFrames
        toValue = 1 * numFrames
      }
      console.log(fromValue, toValue)
      setCurrentStep(currentStep+1)
      animationObject.playSegments([fromValue, toValue])
    }

    useEffect(() => {
        let thingsToLoad = 3
        const decrementThingsToLoad = () => {
          thingsToLoad--
          if (thingsToLoad === 0) {
            setAllLoaded(true)
          }
        }
        let unsubscribeFromJourneyListener = firestore.doc(`journeys/${match.params.id}`).onSnapshot(doc => {
              setJourney({...doc.data(), id: doc.id})
              decrementThingsToLoad()
        })
        let unsubscribeFromExtraInfoListener = firestore.doc(`journeys/${match.params.id}/extra-info/data`).onSnapshot(doc => {
              const data = doc.data()
              setExtraInfo(data)
              decrementThingsToLoad()
        })
        if (lottieEL.current === null) {
          return
        }
        // Get animation:
        let unsubscribeFromAnimationListener = firestore.doc(`journeys/${match.params.id}/animations/main`)
        .onSnapshot(doc => {
          const data = doc.data()
          setAnimationDocument(data)
          const animationData = JSON.parse(data.lottie)
          // let { duration } = data
          // const { steps } = data
          //const numFrames = animationData.op

          // setNumFrames(numFrames) //TODO: <= commented out because of eslint rule!
          const toValueIdx = 1
          const toValue = data.steps[toValueIdx] // <== TODO: cahnge 0 to currentStep
          // if (this.state.currentStep > 0) {
          //   duration = 0
          // } else {
          //   duration *= toValue
          // }
          // duration *= toValue
          const ani = loadAnimation({
                element: lottieEL.current,
                animationData,
                renderer: 'svg',
                wrapper: lottieEL.current,
                autoplay: false,
                autoloadSegments: false
            })
            const numFrames = ani.getDuration(true)
            setAnimationObject(ani)
          decrementThingsToLoad()
          ani.playSegments([data.steps[toValueIdx] - 1, toValue * numFrames], true)
        })
        return () => {
            unsubscribeFromJourneyListener()
            unsubscribeFromExtraInfoListener()
            unsubscribeFromAnimationListener()
        };
      }, []);
    return (<div className={classes.container}>
        { allLoaded ? null : <LinearProgress variant="indeterminate" /> }
        <div ref={lottieEL} className={classes.lottieWrapper}/>
        { allLoaded ? <Stepper className={classes.stepper} activeStep={currentStep} alternativeLabel>
          {extraInfo.files.map(session => (
            <Step key={session.name}>
              <StepLabel>{session.name}</StepLabel>
            </Step>
          ))}
        </Stepper> : null }
        { allLoaded ? 
          <Link component={RouterLink} key={journey.id} to={{
            pathname: `/journeys/${journey.id}/${currentStep}`,
            state: { journey, session: extraInfo.files[currentStep] }
        }}><Button className={classes.startButton}>start {extraInfo.files[currentStep].name}</Button></Link>
          : null
        }
        <Button className={classes.debugButton} onClick={advanceStep}>next step (debug)</Button>
        </div>)
}

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    lottieWrapper: {
      // height: '50vh'
      width: 'calc(100% + 48px)',
      // height: '100%'
      height: 'calc(100vh - 44px)',
      marginTop: -24
    },
    stepper: {
      position: 'absolute',
      top: 64,
      backgroundColor: 'rgba(255,255,255,.8)',
      backdropFilter: 'blur(5px)'
    },
    startButton: {
      position: 'absolute',
      bottom: 20,
      right: 20
    },
    debugButton: {
      position: 'absolute',
      bottom: 20,
      left: 40
    }
  })
Journey.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(Journey)