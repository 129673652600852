export default {
    // BackgroundImagesScreen
    "Once you download images to your device they will show up here": "Sobald du Bilder auf dein Gerät heruntergeladen hast, werden sie hier angezeigt",
    "Download new images": "Lade neue Bilder herunter",


    // Create MYditationScreen
    "Select how you want your meditation to begin:": "Wähle aus, wie deine Meditation beginnen soll:",
    "Select your topic(s) and press next:": "Wähle ein oder mehrere Themen aus und drücke Weiter:",
    "How long would you like to pause after the topic(s) completed? (Background sounds will keep playing)": "Wie lange möchtest du nach Abschluss des Themas pausieren? (Hintergrundelemente spielen weiter)",
    "Select how you would like to be guided out of the meditation:": "Wähle aus, wie du aus der Meditation zurückgeführt werden möchtest:",
    "How long would you the background sounds to continue playing?": "Wie lange möchtest du die Hintergrundelemente weiter abspielen lassen?",
    "Select as many background sounds as you would like:": "Wähle so viele Hintergrundelemente aus wie du möchtest:",

    // DownloadManagerScreen
    "MYDitation Creator Items": "MYDitation Bestandteile",
    "Delete MYDitation Creator Item?": "MYDitation Element löschen?",
    "Are you sure you want to delete {{name}}": "Bist du sicher, dass du {{name}} löschen möchtest?",
    "Delete Journey?": "Reise löschen?",
    "Delete Course?": "Kurs löschen?",

    // EditGoalsScreen
    "What's your daily meditation goal?": "Was ist dein tägliches Meditationsziel?",
    "How many days per week would you like to meditate?": "Wie viele Tage pro Woche würdest du gerne meditieren?",
    "Weekly goal": "Wochenziel",
    "Monthly goal": "Monatliches Ziel",

    // FaqScreen
    // TODO

    // FavouritesScreen
    "You haven't added any favourites yet": "Du hast noch keine Favoriten hinzugefügt",

    // FeedbackScreen
    "General": "Allgemeines",
    "Meditation Suggestion": "Meditationsvorschlag",
    "Topic Suggestion": "Themenvorschlag",
    "App suggestion": "App-Vorschlag",
    "Bug report": "Fehlerbericht",
    "Please enter your suggestion first": "Bitte gebe zuerst deinen Vorschlag ein",
    "We will listen to your feedback": "Wir werden uns dein Feedback durchlesen ...",
    "At MYDitation we work hard on making the best possible products for our customers and always have an open ear for feedback and suggestions": "Das MYDitation Team ist stets bemüht, die bestmöglichen Produkte für unsere Kunden zu kreieren und hat immer ein offenes Ohr für Feedback und Anregungen.",
    "Please select the category:": "Bitte wähle die Kategorie:",
    "Type your feedback or suggestion": "Gib dein Feedback oder deinen Vorschlag ein ...",

    // JourneyScreen
    "Would you like to do this session again?": "Möchtest du diese Sitzung wiederholen?",

    // LibraryMyditationsScreen
    "Record meditations": "Meditationen aufnehmen",

    // LibraryScreen
    "MYditations": "Meine Meditationen",
    "Personal content, record and upload new content": "Persönlicher Inhalt, neue Inhalte aufzeichnen und hochladen",
    "Meditations": "Meditationen",
    "Collection of complete meditations": "Sammlung von vollständigen Meditationen",
    "Music": "Musik",
    "Collection of soothing and relaxing music": "Sammlung beruhigender und entspannender Musik",
    "Healing Sounds": "Heilende Klänge",
    "Binaural beats & Solfeggio tones": "Binaural & Solfeggio Töne",
    "Visuals": "Bilder",
    "Collection of soothing and relaxing backgrounds": "Sammlung beruhigender und entspannender Hintergründe",

    // MyDiateScreen
    "Create your Meditation": "Erstelle deine Meditation",
    "Previous Meditations": "Vorherige Meditationen",
    "Start": "Start",
    'START': 'START',
    "nothing to show yet": "Noch nichts zu zeigen",

    // MYditationScreen
    "Meditation finished": "Meditation beendet",
    "Would you like to save it?": "Möchtest du die Meditation speichern?",

    // PrivacyPolicyScreen
    // TODO

    // ProfileScreen
    "Take photo with camera": "Mach ein Foto mit der Kamera",
    "Pick from library": "Wähle aus der Bibliothek aus",
    "Set Goals": "Ziele setzen",
    "Register free account": "Kostenloses Konto registrieren",
    "Register a free account to unlock all the features of the app!": "Registriere ein kostenloses Konto, um alle Funktionen der App freizuschalten!",
    "Set reminders": "Erinnerungen festlegen",
    "Favourites": "Favoriten",
    "Downloads": "Downloads",
    "Restore Purchases": "Einkäufe wiederherstellen",
    "restoring purchases...": "Einkäufe werden wiederhergestellt ...",
    "itunes Error": "itunes Fehler",
    "Could not connect to itunes store.": "Es konnte keine Verbindung zum iTunes Store hergestellt werden.",
    "No Purchases": "Keine Einkäufe",
    "We didn't find any purchases to restore.": "Wir haben keine Einkäufe für die Wiederherstellung gefunden.",
    "FAQ": "FAQ",
    "Feedback": "Feedback",
    "Rate app": "App bewerten",
    "YouTube": "Youtube",
    "Terms & Conditions": "Allgemeine Geschäftsbedingungen",
    "Privacy Policy": "Datenschutzbestimmungen",
    "Sign out": "Abmelden",
    "Yes, sign out": "Ja, abmelden",
    "Account": "Konto",

    // RecordMeditationScreen
    "Name can't be empty": "Der Name darf nicht leer sein",
    "Name not unique": "Name schon vergeben",
    "A recording with the name {{newRecordingName}} already exists for {{newRecordingCategory}}": "Eine Aufnahme mit dem Namen {{newRecordingName}} existiert bereits für {{newRecordingCategory}}",
    "Use": "Benutze den",
    "button to add a new recording": "Knopf um eine neue Aufnahme hinzuzufügen.",

    // SetRemindersScreen
    "MYDitation Reminder": "MYDitation Erinnerung",
    "It's time to meditate!": "Es ist Zeit zu meditieren!",
    "Daily?": "Täglich?",
    "Set all days": "Stelle alle Tage ein",
    "Set daily reminder time": "Stelle die tägliche Erinnerungszeit ein",
    "Set reminder time for": "Stelle die Erinnerungszeit ein für",
    "all days": "alle Tage",
    "Cancel all reminders": "Alle Erinnerungen abstellen",

    // TaCScreen
    // TODO
    
    // Error messages
    "Error loading data\nTry again later": "Fehler beim Laden der Daten\nBitte versuche es später noch einmal",
    "Error": "Fehler",

    // Other alerts
    "Not yet unlocked": "Noch nicht freigeschaltet",
    "You need to complete the previous sessions first before you can access {{name}}": "Die vorherigen Sitzungen müssen erst abgeschlossen sein, bevor du auf {{name}} zugreifen kannst.",

    // Shared
    "cancel": "Abbrechen",
    "yes": "Ja",
    "Success": "Erfolg",
    "Successfully deleted {{name}}": "Erfolgreich {{name}} gelöscht",
    "Journeys": "Reisen",
    "Courses": "Kurse",
    "Thank You": "Danke",
    "send": "senden",
    "Well done!": "Gut gemacht!",
    "You have finished {{name}}": "Du hast {{name}} erfolgreich abgeschlossen",
    "OK": "OK",
    "Error opening link": "Fehler beim Öffnen des Links",
    "Day": "Tag",
    "Week": "Woche",
    "Month": "Monat",
    "Are you sure?": "Bist du sicher?",
    "Save": "Speichern",
    "Reset": "Zurücksetzen",

    // V2
    //Creator
    'Introduction': 'Intro',
    'Topics': 'Themen',
    'Ending': 'Zurückführung',
    'Pause': 'Pause',
    'Backgrounds': 'Hintergründe',
    // 'skip': 'überspringen',
    // 'next': 'weiter',
    // 'back': 'zurück',
    'skip': 'ÜBERSPRINGEN',
    'next': 'WEITER',
    'back': 'ZURÜCK',
    'minutes': 'Minuten',
    //Tabs und headers
    'MYDitate':'Erstellen',
    'Library': 'Bibliothek',
    'Profile': 'Profil',
    'START SESSION': 'BEGINNE SITZUNG',
    'Saved recordings': 'Gespeicherte Aufnahmen',
    'Set category': 'Kategorie wählen',
    'Select the category for {{name}}': 'Wähle die Kategorie für {{name}}',
    'My Images': 'Meine Bilder',
    'Introduction [audio only]': 'Intro abspielen',
    'Start 30-day free trial': 'Premium 30 Tage kostenlos testen!',

    //V3
    //CustomMeditationPreview
    'then pause for {{pause}} minute': '{{pause}} Minute Pause',
    'then pause for {{pause}} minutes': '{{pause}} Minuten Pause',
    'Background Sounds': 'Hintergrundelemente',
    'Topic': 'Thema',

    //V4 Neuer home screen etc..
    'Browse meditations': 'Meditieren',
    'Create custom meditation': 'Eigene Meditation erstellen',
    'Languages': 'Sprachen',
    'english': 'Englisch',
    'german': 'Deutsch',
    'no': 'Nein',
    'MYditate': 'MYditate',
    'Please restart the app for changes to take effect': 'Bitte starten Sie die App neu, damit die Änderungen wirksam werden.',
    'language': 'Sprache',
    'app language text': 'Bitte wähle die Sprache für die App aus. (Die App muss neustarten, um die Änderungen anzuzeigen)',
    'Edit goals': 'Ziele bearbeiten',
    "Set goals": "Ziele setzen",
    'Meditate': 'Meditieren',
    'Browse through meditations': 'Stöbere in unserer Sammlung an geführten Meditationen',
    'Create meditation': 'Meditation erstellen',
    'Create meditation text': 'Wähle aus unseren einzelenen Elementen für deine individuelle Meditation',
    'Level': 'Level',
    'Lessons': 'Lektionen',
    'Record Meditation': 'Meditation aufnehmen',

    // Subscribe modal:
    'Unlock': 'Alles freischalten',
    'New & updated content added every week': 'Neue Inhalte jede Woche',
    'Entire library containing hours of': 'Eine ständig wachsende Bibliothek mit',
    'guided meditations': 'geführten Meditationen',
    'soothing music': 'Entspannungsmusik',
    'healing frequencies': 'heilenden Frequenzen',
    'nature sounds': 'Naturgeräuschen',
    'Video Courses and animated Meditation Journeys': 'Kurse und animierte Meditations-Reisen',
    'Full access to all MYditation creator content': 'Zugriff auf alle individuellen Meditationen & Elemente',
    'Start your': 'Jetzt',
    '30 DAY FREE TRIAL': '30 Tage kostenlos testen',
    'and afterwards continue with:': 'und danach unverbindlich fortfahren:',
    'Monthly': 'Monatlich',
    'billed monthly': 'monatlich zahlbar',
    'BEST VALUE': 'SONDERANGEBOT',
    'Yearly': 'Jährlich',
    'billed yearly': 'einmal im Jahr zahlbar',
    '3 months': '3 Monate',
    'billed quarterly': 'vierteljährlich zahlbar',
    'long legal text': 'Zahlungen werden Ihrem iTunes-Konto mit der Kaufbestätigung belastet. Das Abonnement wird automatisch verlängert, sofern die automatische Verlängerung nicht mindestens 24 Stunden vor dem Ende des aktuellen Zeitraums deaktiviert ist. Das Konto wird für die Verlängerung innerhalb von 24 Stunden vor dem Ende des laufenden Zeitraums in Rechnung gestellt und ermittelt die Kosten für die Verlängerung. Abonnements können vom Benutzer verwaltet werden und die automatische Verlängerung kann nach dem Kauf in den Kontoeinstellungen des Benutzers deaktiviert werden. Jeder ungenutzte Teil eines kostenlosen Testzeitraums verfällt, wenn er angeboten wird, wenn der Benutzer ein Abonnement für diese Publikation kauft, sofern zutreffend.',

    // V5
    'introduction': 'Intro',
    'topic': 'Thema',
    'ending': 'Zurückführung',
    'YouTube-link': 'https://www.youtube.com/channel/UC1Sld6ZBkekLMCjGvWueGGg',
    'de': 'Deutsch',
    'en': 'Englisch',
    // Ab hier is neu
    'App language set to': 'App Sprache ausgewählt',
    'Preferences': 'Bevorzugungen',
    'Customise the app to your preferences': 'Personalisiere die App mit deinen Vorzügen.',
    'You can change these later on the profile screen': 'Du kannst diese später noch im Profil ändern.',
    'Select app language': 'Wähle die Sprache der App aus',
    'Language changes will be applied after you press the done button': 'Spracheinstellungen werden gespeichert sobald du sie bestätigst.',
    'Select your interests': 'Wähle deine Interessen aus',
    'Weight Loss': 'Abnehmen',
    'Sleep': 'Schlafen',
    'Motivation': 'Motivation',
    'Traditional Methods': 'Traditionelle Methoden',
    'Relaxation': 'Entspannung',
    'Anxiety & Stress Reduction': 'Ängste & Stressreduzierung',
    'Health': 'Gesundheit',
    'Stress Reduction': 'Stressreduzierung',
    'Pregnancy': 'Schwangerschaft',
    'Mindfulness': 'Achtsamkeit',

    // Sign-up Modal
    'Sign-up or login': 'Anmelden oder einloggen',
    'To get the most out of MYditation, we recommend you sign-up for a free account or login if you already have an account': 'Um MYDitation am Besten zu benutzen raten wir dir ein kostenfreies Konto zu registrieren',
    // 'later': 'später',
    // 'dont show again': 'nicht wieder anzeigen',
    'later': 'SPÄTER',
    'dont show again': 'NICHT WIEDER ANZEIGEN',
    'By using this app you agree to our': '',
    'terms': 'Geschäftsbedingungen',
    'and our': 'und unsere',
    'privacy policy': 'Datenschutzbestimmungen',
    'Already have an account': 'Hast du schon ein Konto?',
    'login with facebook': 'Mit Facebook einloggen',
    'REGISTER': 'REGISTRIEREN',
    'password': 'Passwort',
    'email': 'Email',

    // V6
    'load previous meditation': 'Gespeicherte Meditation laden',

    // Intro (onb-oarding)
    'Welcome to MYDitation': 'Herzlich Willkommen zu MYditation',
    'your unique meditation experience': 'deinem einzigartigen Meditations-Erlebnis',
    'Language': 'Sprache',
    'Interests': 'Interessen',
    'Please select your preferences so that we can adjust the content to your personal needs': 'Wähle bitte deine Interessen aus, sodass wir den Inhalt besser an deine Bedürfnisse anpassen können:',
    'Goals': 'Ziele',
    'Goals-subtext': 'Übung macht den Meister!',
    'Goals-paragraph': 'Um deine Ziele zu errreichen und vom Meditieren dauerhaft zu profitieren, kannst du dir hier Meditations-Ziele setzen und dich an diese auch erinnern lassen.',
    'Frequencies': 'Frequenzen',
    'Frequencies-subtext': 'Solfeggio & Binaural Töne',
    'frequencies-subtext-pre-learn-more': 'In dieser App hast du die Möglichkeit alle Meditationen mit Hintergrund-Elementen, wie heilenden Frequenzen',
    'frequencies-subtext-learn-more': 'mehr erfahren',
    'frequencies-subtext-post-learn-more': 'Musik und Ambience zu bereichern. Du kannst diese Hintergrund-Elmente während oder vor der Meditation selber festlegen. Bei vielen Meditationen haben wir schon passende Voreinstellungen festgelgt. Möchtest du diese Voreinstellungen automatisch laden, oder willst du lieber nur die Stimmanleitung hören?',
    'Meditation-Creator': 'Meditation-Creator',
    'Meditation-Creator-subtext': 'Erstelle deine eigenen Meditationen',
    'Meditation-Creator-subtext-p1': "Der 'Meditationen-Ersteller' gibt dir die Möglichkeit, deine ganz individuelle Sitzung zu gestalten. Du kannst von unseren Meditationen [Intros, Themen etc.] auswählen und sie ganz nach deinem Belieben zusammenstellen - du kannst aber auch deine eigenen Meditationen oder Elemente aufnehmen und diese im Ersteller verwenden.",
    'Meditation-Creator-subtext-p2': 'Zudem kannst du auch hier festlegen, welche Hintergrundelemente es während der Meditation abspielt, um die für dich perfekte Atmosphäre zu erstellen, bei der du dich vollkommen auf die Meditation konzentrieren und entspannen kannst.',
    'finish': 'FERTIG',

    //NEU
    'Lifetime': 'Lebenslang',
    'billed once': 'Einmalzahlung',
    'Only {{price}} per month': 'Entspricht nur {{price}} pro Monat',
    'Voice only': 'Nur Stimmanleitung',
    'Voice with background audio presets': 'Stimmanleitung & Hintergrundelemente',

    //NEUER
    'App content languages': 'Sprachen für den Inhalt der App:',
    'Select the languages you would like to show app content in': 'Bitte wähle die Sprache/n für den Inhalt der App aus.',
    'Are you sure you want to change the app language': 'Bist du dir sicher, dass du die App-Sprache ändern möchtest?',
    'If you select yes, the app will automatically restart in the language you have chosen': 'Wenn du ja klickst, startet sich die App automatisch neu und wechselt in deine gewählte Sprache.',
    'Reminders': 'Erinnerungen',
    'Weekly reminders set successfully!': 'Wöchentliche Erinnerungen erfolgreich gespeichert!',
    'Weekly reminder set successfully!': 'Wöchentliche Erinnerung erfolgreich gespeichert!',
    'Daily reminder set successfully!': 'Tägliche Erinnerung erfolgreich gespeichert!',
    'No downloads yet': 'Noch keine Downloads',
    'UNLOCK EVERYTHING': 'ALLES FREISCHALTEN',
    'You need to be subscribed to access this content': 'Du brauchst ein Abo um auf diesen Inhalt zugreifen zu können',
    'learn more': 'Mehr erfahren',
    'Email invalid': 'Ungültige E-Mail',
    'Please enter a valid email address to request a password reset': 'Bitte gib eine gültige E-Mail Adresse ein, um dein Passwort zurückzusetzen',
    'Password reset success': 'Passwort erfolgreich zurückgesetzt',
    'Please check your email for instructions on how to reset you password': 'Bitte überprüfe deine E-Mails für weitere Anleitungen',
    'Error resetting password': 'Fehler beim Zurücksetzen aufgetreten',
    'Sign in': 'Einloggen',
    'Forgot password': 'Passwort vergessen?',
    'Please sign in with the account you made the purchases with to restore your purchases': 'Bitte melde dich mit dem gleichen Konto an, welches du für den EInkauf genutzt hast.',
    'close': 'schließen',
    'preview': 'Vorschau',
    'Settings': 'Einstellungen',
    'Use audio presets': 'Audio Voreinstellungen benutzen',
    'minute': 'Minute',
    'edit selections': 'Auswahl bearbeiten',
    'Unnamed': 'Unbenannt',
    'Edit Meditation': 'Meditation bearbeiten',
    'name': 'Name',
    'Download in progress': 'Download läuft...',
    'Please wait until the download has finished': 'Bitte warten Sie, bis der Download abgeschlossen ist.',
    'creator-item': 'Meditations-Ersteller Element',
    'library-item': 'Bibliothek Element',
    'At the end of a meditation': 'Am Ende einer Meditation...',
    'the music stops immediately': '...the music stops immediately',
    'stops playing immediately': 'stopt sofort',
    'stops immediately': 'stopt sofort',
    'Sounds a gong': 'Ertönt ein Gongschlag',
    'sounds a gong and ': 'ertönt ein Gong und ',
    'the music stops playing immediately': 'die Musik stopt sofort',
    'the music stops playing immediately2': 'stopt die Musik sofort.',
    '':'stopt die Musik sofort.',
    'the music continues to play for {{minutes}} minutes': 'die Musik spielt weiter für {{minutes}} Minuten.',
    'the music continues to play for {{minutes}} minutes2': 'spielt die Musik weiter für {{minutes}} Minuten.',
    'The music continues to play for': 'Spielt die Musik weiter für',
    // 'sounds a gong and the music stops playing immediately': '...sounds a gong and the music stops playing immediately',
    // 'sounds a gong and the music keeps playing for {{minutes}} minutes': '...sounds a gong and the music keeps playing for {{minutes}} minutes'
    'Press here to learn more': 'Hier klicken um mehr zu erfahren',
    'Fix broken downloads': 'GESPEICHERTE DATEIEN PRÜFEN',
    'If playback of downloaded files is not working': 'Falls gespeicherte Dateien nicht mehr abspielen sollten, kannst du sie mit dieser Funktion prüfen lassen. Fehlerhafte Dateien werden dadurch entfernt und müssen erneut gespeichert werden, um sie offline anhören zu können. Vielen Dank für dein Verständnis. Namasté.',

    //NEW:
    'Meditation image': 'Meditations Bild'
}