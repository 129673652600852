import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import ImageButton from './ImageButton'
import { Link as RouterLink } from "react-router-dom"
import Link from '@material-ui/core/Link'

import meditationsImage from '../assets/img/library/meditations.png'
import musicImage from '../assets/img/library/music.png'
import healingFrequenciesImage from '../assets/img/library/healing-frequencies.png'
// import { LanguageContext } from '../Contexts/language-context'
import { withTranslation } from 'react-i18next'

function Library(props) {
    const { classes, t } = props
    // const language = useContext(LanguageContext)

    return ( <div className={classes.container}>
        <Link component={RouterLink} to="/library/meditations">
            <ImageButton title={t('Meditations')}
            subtitle={t('Collection of complete meditations')}
            imgSrc={meditationsImage}
            className={classes.imgButton} />
        </Link>
        <Link component={RouterLink} to="/library/music">
            <ImageButton title={t('Music')}
            subtitle={t('Collection of soothing and relaxing music')}
            imgSrc={musicImage}
            className={classes.imgButton} />
        </Link>
        <Link component={RouterLink} to="/library/healing-frequencies">
            <ImageButton title={t('Healing Sounds')}
            subtitle={t('Binaural beats & Solfeggio tones')}
            imgSrc={healingFrequenciesImage}
            className={classes.imgButton} />
        </Link>
        </div>)
}

const styles = theme => ({
    container: {
        position: 'relative',
        height: '100%',
        width: '100%'
    },
    imgButton: {
        margin: 5
    }
  })

  Library.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTranslation()(withStyles(styles)(Library))