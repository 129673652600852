// import './Dashboard.css'
import 'firebase/auth'
import 'firebase/firestore'
import 'typeface-roboto'

import { AppBar, MenuItem, CssBaseline, IconButton, Toolbar, Typography, Select, Dialog, LinearProgress } from '@material-ui/core'
import { 
  Menu
} from '@material-ui/icons'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import { firebase } from './firebase'
import Flag from 'react-world-flags'
import Home from './Components/Home'
import MeditationCreator from './Components/MeditationCreator'
import CustomMeditationScreen from './Components/CustomMeditationScreen'
import Courses from './Components/Courses'
import Course from './Components/Course'
import CourseDay from './Components/CourseDay'
import Journeys from './Components/Journeys'
import Journey from './Components/Journey'
import JourneySession from './Components/JourneySession'
import SideMenu from './Components/SideMenu'
import InstructorSession from './Components/InstructorSession'
import Chat from './Components/Chat'
import Library from './Components/Library'
import LibraryItems from './Components/LibraryItems'
import LibraryCategories from './Components/LibraryCategories'
import LibraryMeditation from './Components/LibraryMeditation'
import Profile from './Components/Profile'
import Lab from './Components/Lab'
import Draggable from 'react-draggable'
import CompactAudioPlayer from './Components/CompactAudioPlayer'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { Route, BrowserRouter as Router } from "react-router-dom"
import { auth, firestore } from './firebase'
import { LanguageContext } from './Contexts/language-context'
import { ContentFullscreenContext } from './Contexts/content-fullscreen'
import { UserDataContext } from './Contexts/user-data'
import { UserDataSettingsContext } from './Contexts/user-data-settings'
import { UserDataJourneysContext } from './Contexts/user-data-journeys'
import { UserDataCoursesContext } from './Contexts/user-data-courses'
import { SubscriptionContext } from './Contexts/subscription-context'
//TODO: Journeys Context for journey progress
//      Favourites!
import i18n from './i18n'
import { Tween, autoPlay } from 'es6-tween'
import { showSubscriptionAlert } from './inter-component-comunication'
autoPlay(true)

const AUDIO_PLAYER_MODE_TRANSITION_MS = 500

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#754D8A',
      main: '#28003D',
      dark: '#0F0024',
      contrastText: '#fff',
    },
    secondary: {
      light: '#FFFFA6',
      main: '#FFDD59',
      dark: '#B3910D',
      contrastText: '#000',
    }
  }
})

const drawerWidth = 240

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin', 'transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarrFullScreen: {
        transform: 'translate3d(0,-64px,0)'
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto',
    position: 'relative'
  }
});

function Dashboard ({ classes }) {

  const setIsContentFullscreen = isContentFullscreen => {
    setContentFullscreenContext({
      isContentFullscreen,
      setIsContentFullscreen: setIsContentFullscreen
    })
    if (document.fullscreenEnabled) {
      if (isContentFullscreen) {
        document.documentElement.requestFullscreen()
      } else {
        document.exitFullscreen()
      }
    }
  }

  // state = {
  //   appIsReady: false,
  //   open: false,
  //   audioPlayerItem: null,
  //   audioPlayerPlaylist: [],
  //   audioPlayerPlaylistIndex: 0,
  //   language: 'de',
  //   isCompactPlayerDragDisabled: false,
  //   contentFullscreenContext: {
  //     isContentFullscreen: false,
  //     setIsContentFullscreen: this.setIsContentFullscreen
  //   },
  //   userData: {},
  //   userDataSettings: {
  //     interests: {
  //       topics: {}
  //     },
  //     audioPresets: {
  //       use: true
  //     },
  //     meditation: {
  //       playGongAtEnd: false,
  //       continuePlayingMusicForMinutes: 0
  //     }
  //   },
  //   userDataJourneys: {},
  //   userDataCourses: {},
  //   compactPlayerPosition: { x: 0, y: 0 },
  //   compactPlayerMode: 'free',
  //   subscription: {
  //     expiresAt: 0,
  //     subscriptionId: null,
  //     active: false
  //   },
  //   isSubscriptionAlertShowing: false
  // }

  const [ appIsReady, setAppIsReady ] = useState(false)
  const [ open, setOpen ] = useState(false)
  const [ audioPlayerItem, setAudioPlayerItem ] = useState(null)
  const [ audioPlayerPlaylist, setAudioPlayerPlaylist ] = useState([])
  const [ audioPlayerPlaylistIndex, setAudioPlayerPlaylistIndex ] = useState(0)
  const [ language, setLanguage ] = useState("de")
  const [ isCompactPlayerDragDisabled, setIsCompactPlayerDragDisabled ] = useState(false)
  const [ contentFullscreenContext, setContentFullscreenContext ] = useState({ isContentFullscreen : false })
  const [ userData, setUserData ] = useState({})
  const [ userDataSettings, setUserDataSettings ] = useState({ interests: { topics: {} }, audioPresets: { use: true }, meditation: { playGongAtEnd:false, continuePlayingMusicForMinutes:0 }})
  const [ userDataJourneys, setUserDataJourneys ] = useState({})
  const [ userDataCourses, setUserDataCourses ] = useState({})
  const [ compactPlayerPosition, setCompactPlayerPosition ] = useState({ x: 0, y: 0 })
  const [ compactPlayerMode, setCompactPlayerMode ] = useState("free")
  const [ subscription, setSubscription ] = useState({ expiresAt: 0, subscriptionId: null, active: false })
  const [ isSubscriptionAlertShowing, setIsSubscriptionAlertShowing ] = useState(false)
  
  const loadUserData = uid => new Promise(resolve => {
    const unsubscribeFromUserDataListener = firestore.doc(`user-data/${uid}`)
    .onSnapshot(doc => {
      setUserData(doc.data())
      resolve(unsubscribeFromUserDataListener)
    })
  })
  
  const loadUserDataSettings = uid => new Promise(resolve => {
    const unsubscribeFromUserDataSettingsListener = firestore.collection(`user-data/${uid}/settings`)
    .onSnapshot(async snap => {
      const userDataSettings = {...userDataSettings}
      for (const doc of snap.docs) {
        userDataSettings[doc.id] = doc.data()
      }
      setUserDataSettings(userDataSettings)
      resolve(unsubscribeFromUserDataSettingsListener)
    })
  })
  
  const loadUserDataJourneys = uid => new Promise(resolve => {
    const unsubscribeFromUserDataJourneysListener = firestore.collection(`user-data/${uid}/journeys`)
    .onSnapshot(async snap => {
      const userDataJourneys = {}
      for (const doc of snap.docs) {
        userDataJourneys[doc.id] = doc.data()
      }
      setUserDataJourneys(userDataJourneys)
      resolve(unsubscribeFromUserDataJourneysListener)
    })
  })
  
  const loadUserDataCourses = uid => new Promise(resolve => {
    const unsubscribeFromUserDataCoursesListener = firestore.collection(`user-data/${uid}/courses`)
    .onSnapshot(async snap => {
      const userDataCourses = {}
      for (const doc of snap.docs) {
        userDataCourses[doc.id] = doc.data()
      }
      setUserDataCourses(userDataCourses)
      resolve(unsubscribeFromUserDataCoursesListener)
    })
  })
  
  const loadSubscription = uid => new Promise(resolve => {
    const unsubscribeFromSubscriptionListener = firestore.doc(`subscriptions/${uid}`)
    .onSnapshot(doc => {
      if (doc.exists) {
        const subscription = doc.data()
        console.log('got subscription', subscription)
        const serverTime = firebase.firestore.Timestamp.now().toMillis()
        subscription.active = subscription.expiresAt > serverTime
        // subscription.active = false
        setSubscription(subscription)
        resolve(unsubscribeFromSubscriptionListener)
      } else {
        resolve(unsubscribeFromSubscriptionListener)
      }
    })
  })

  const handleDrawerOpen = () => {
    setOpen(true)
  };

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const setAudioPlayerItemWrap = audioPlayerItem => {
    //TODO: If playlist empty, just replace, else add to end of playlist
    //      and set to active playlist index!!
    // if (this.state.audioPlayerPlaylist.length === 0) {
    //   this.setState({ audioPlayerPlaylist: [ audioPlayerItem ] })
    // }
    setAudioPlayerPlaylist([...audioPlayerPlaylist, audioPlayerItem])
    setAudioPlayerPlaylistIndex(audioPlayerPlaylist.length - 1)
    setAudioPlayerItem(audioPlayerItem)
  }

  const setPlayListItem = audioPlayerPlaylistIndex => {
    setAudioPlayerItem(audioPlayerPlaylist[audioPlayerPlaylistIndex])
    setAudioPlayerPlaylistIndex(audioPlayerPlaylistIndex)
  }

  const seekAudioPlayerItem = value => {
    if (audioPlayerItem) {
      audioPlayerItem.seek(value * audioPlayerItem.duration)
    }
  }

  const playNextPlaylistItem = () => {
    if (audioPlayerPlaylistIndex < audioPlayerPlaylist.length) {
      setPlayListItem(audioPlayerPlaylistIndex + 1)
    }
  }

  const setCompactPlayerDragDisabled = isCompactPlayerDragDisabled => {
    setIsCompactPlayerDragDisabled(isCompactPlayerDragDisabled)
  }

  const setCompactPlayerToTopBarMode = () => {
    setIsCompactPlayerDragDisabled(true)
    setCompactPlayerMode('top-bar')
    const tween = new Tween(compactPlayerPosition)
      .to({ x: 75, y: 0 }, AUDIO_PLAYER_MODE_TRANSITION_MS)
      .on('update', (compactPlayerPosition) => {
        setCompactPlayerPosition(compactPlayerPosition)
      })
      tween.start()
  }

  const setCompactPlayerToFreeMode = () => {
    setIsCompactPlayerDragDisabled(false)
    setCompactPlayerMode('free')
    setCompactPlayerPosition(null)
  }

  const toggleCompactPlayerMode = () => {
    if (compactPlayerMode === 'free') {
      setCompactPlayerToTopBarMode()
    } else {
      setCompactPlayerToFreeMode()
    }
  }

  const handlePresence = uid => {
    const userStatusDatabaseRef = firebase.database().ref(`/status/${uid}`)
    const userStatusFirestoreRef = firebase.firestore().doc(`/status/${uid}`)
    const isOfflineForDatabase = {
        state: 'offline',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
    }
    const isOnlineForDatabase = {
        state: 'online',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
    }
    const isOfflineForFirestore = {
        state: 'offline',
        last_changed: firebase.firestore.FieldValue.serverTimestamp(),
    }
    const isOnlineForFirestore = {
        state: 'online',
        last_changed: firebase.firestore.FieldValue.serverTimestamp(),
    }
    firebase.database().ref('.info/connected').on('value', snapshot => {
      if (snapshot.val() === false) {
          userStatusFirestoreRef.set(isOfflineForFirestore)
        return
    }
        userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(() => {
            userStatusDatabaseRef.set(isOnlineForDatabase)
            userStatusFirestoreRef.set(isOnlineForFirestore)
        })
    })
    // Can use next few lines to listen to on/off-line events:
    // userStatusFirestoreRef.onSnapshot(doc => {
    //     const isOnline = doc.data().state = 'online'
    //     // ... use isOnline
    // })
  }

  const onChangeLanguage = evt => {
    const language = evt.target.value
    i18n.changeLanguage(language)
    setLanguage(language)
  }

  const handleCompactAudioPlayerDrag = (evt, data) => {
    const { x, y } = data
    if (!isCompactPlayerDragDisabled) {
      setCompactPlayerPosition({ x, y })
    }
  }

  const hideSubscriptionAlert = () => {
    setIsSubscriptionAlertShowing(false)
  }

  useEffect(() => {
    let unsubscribeFunctions
    const fetchData = async () => {
      const { uid } = auth.currentUser
      handlePresence(uid)
      unsubscribeFunctions = await Promise.all([
        loadUserData(uid),
        loadUserDataSettings(uid),
        loadSubscription(uid),
        loadUserDataJourneys(uid),
        loadUserDataCourses(uid)
      ])
      setAppIsReady(true)

      showSubscriptionAlert.on = () => {
        setIsSubscriptionAlertShowing(true)
      }
    }
    fetchData()
    return () => {
      for (const unsubscribeFunction of unsubscribeFunctions) {
        unsubscribeFunction()
      }
    }
  }, [])

  // const { classes } = this.props
  //   const {
  //     appIsReady,
  //     open,
  //     audioPlayerItem,
  //     audioPlayerPlaylist,
  //     audioPlayerPlaylistIndex,
  //     language,
  //     isCompactPlayerDragDisabled,
  //     contentFullscreenContext,
  //     userData,
  //     subscription,
  //     userDataSettings,
  //     compactPlayerPosition,
  //     compactPlayerMode,
  //     isSubscriptionAlertShowing,
  //     userDataJourneys,
  //     userDataCourses
  //   } = this.state
    const { isContentFullscreen } = contentFullscreenContext

    return appIsReady ? (<Router><MuiThemeProvider theme={theme}>
      <LanguageContext.Provider value={language}>
      <ContentFullscreenContext.Provider value={contentFullscreenContext}>
      <UserDataContext.Provider value={userData}>
      <UserDataSettingsContext.Provider value={userDataSettings}>
      <UserDataJourneysContext.Provider value={userDataJourneys}>
      <UserDataCoursesContext.Provider value={userDataCourses}>
      <SubscriptionContext.Provider value={subscription}>
      <div className={classes.root}>
      <CssBaseline />
      {
    audioPlayerItem && <Draggable onDrag={evt => {
      if (isCompactPlayerDragDisabled) {
        return false
      }
    }}
    disabled={isCompactPlayerDragDisabled}
    position={compactPlayerPosition}
    onStop={handleCompactAudioPlayerDrag}>
        <div style={{position: 'absolute', zIndex: 99999}}>
          <CompactAudioPlayer item={audioPlayerItem}
          playlist={audioPlayerPlaylist}
          setItem={setAudioPlayerItemWrap}
          setPlaylist={setAudioPlayerPlaylist}
          playListIndex={audioPlayerPlaylistIndex}
          setPlayListIndex={setAudioPlayerPlaylistIndex}
          seekAudioPlayerItem={seekAudioPlayerItem}
          setCompactPlayerDragDisabled={setCompactPlayerDragDisabled}
          setPlayListItem={setPlayListItem}
          playNextPlaylistItem={playNextPlaylistItem}
          mode={compactPlayerMode}
          toggleCompactPlayerMode={toggleCompactPlayerMode} />
        </div>
        </Draggable>
    }
      <AppBar
        position="absolute"
        className={classNames(classes.appBar, open && classes.appBarShift, isContentFullscreen && classes.appBarrFullScreen)}
      >
      <Toolbar disableGutters={!open} className={classes.toolbar}>
      <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                open && classes.menuButtonHidden,
              )}
            >
              <Menu />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              MYDitation
            </Typography>
            <Select
              value={language}
              onChange={onChangeLanguage}
              autoWidth={true}
            >
              <MenuItem value="de"><Flag code="de" height={24}/></MenuItem>
              <MenuItem value="en"><Flag code="gb" height={24}/></MenuItem>
            </Select>
      </Toolbar>
      </AppBar>
      <Route render={props => <SideMenu open={open} isContentFullscreen={isContentFullscreen} handleDrawerClose={handleDrawerClose} {...props} />} />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
            <Route path="/" exact={true} component={Home} />
            <Route path="/meditation-creator" exact={true} component={MeditationCreator} />
            <Route path="/custom-meditation/:id" exact={true} component={CustomMeditationScreen} />
            <Route path="/courses" exact={true} component={Courses} />
            <Route path="/courses/:id" exact={true} component={Course} />
            <Route path="/courses/:id/:dayIdx" component={CourseDay} />
            <Route path="/journeys" exact={true} component={Journeys} />
            <Route path="/journeys/:id" exact={true} component={Journey} />
            <Route path="/journeys/:id/:sessionIdx" component={JourneySession} />
            <Route path="/instructor-session" exact={true} component={InstructorSession} />
            <Route path="/instructor-session/chat/:chatUid" component={Chat} />
            <Route path="/library" exact={true} component={Library} />
            <Route path="/library/meditations" exact={true} render={() => <LibraryCategories dbPath="library/meditations/items" />} />
            <Route path="/library/meditations/:category"  exact={true} render={props => <LibraryItems dbPath={`/library/meditations/items/${props.match.params.category}/items`} onItemClick={item => {
              console.log('item', item)
              props.history.push(`/library/meditations/${props.match.params.category}/${item.id}`)
            }} />} />
            <Route path="/library/meditations/:category/:id" render={props => <LibraryMeditation dbPath={`/library/meditations/items/${props.match.params.category}/items/${props.match.params.id}`} />} />
            <Route path="/library/music" render={() => <LibraryItems dbPath="/library/music/items" onItemClick={setAudioPlayerItemWrap}
            onAddToPlaylistClick={item => {
              setAudioPlayerPlaylist([...audioPlayerPlaylist, item])
            }} />} />
            <Route path="/library/healing-frequencies" exact={true} render={() => <LibraryCategories dbPath="library/healing-frequencies/items" /> } />
            <Route path="/library/healing-frequencies/:category" render={props => <LibraryItems dbPath={`library/healing-frequencies/items/${props.match.params.category}/items`} onItemClick={setAudioPlayerItemWrap} /> } />
            <Route path="/profile" component={Profile} />
            <Route path="/lab" component={Lab} />
        </main>
        <Dialog open={isSubscriptionAlertShowing} onClose={hideSubscriptionAlert}>
            No active subscription....
        </Dialog>
      </div>
      </SubscriptionContext.Provider>
      </UserDataCoursesContext.Provider>
      </UserDataJourneysContext.Provider>
      </UserDataSettingsContext.Provider>
      </UserDataContext.Provider>
      </ContentFullscreenContext.Provider>
      </LanguageContext.Provider>
      </MuiThemeProvider></Router>) : <LinearProgress/>
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard)