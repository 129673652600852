import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Slider, Grid, Box, IconButton } from '@material-ui/core'
import oscillators from 'web-audio-oscillators'
import { Knob } from 'react-rotary-knob'
import { s10 } from 'react-rotary-knob-skin-pack'
import { RemoveCircle } from '@material-ui/icons'

const INSTRUMENTS = Object.keys(oscillators)

const context = new (window.AudioContext || window.webkitAudioContext)()

const solfeggioFrequencies = [396, 417, 528, 639, 741, 862]
const minFreq = solfeggioFrequencies[0]
const maxFreq = solfeggioFrequencies[solfeggioFrequencies.length - 1]
const solfeggioMarks = solfeggioFrequencies
.map(value => ({
    value, label: `${value}Hz`
}))

const styles = theme => ({
    container: {
        marginRight: 40
    }
})

function SolfeggioSlider(props) {
    const { onDragStart, onDragEnd, onRemove } = props

    const [ freq, setFreq ] = useState(minFreq)
    const [ volume, setVolume ] = useState(.5)

    const oscillatorRef = useRef(oscillators[INSTRUMENTS[0]](context))
    const carrier_volumeRef = useRef(context.createGain())

    useEffect(() => {
        console.log('yo')

        const oscillator = oscillatorRef.current
        oscillator.frequency.value = minFreq

        const carrier_volume = carrier_volumeRef.current
        carrier_volume.gain.linearRampToValueAtTime(.5, 0)
        oscillator.connect(carrier_volume)
        carrier_volume.connect(context.destination)

        oscillator.start()
        return () => {
            oscillator.stop()
            oscillator.disconnect()
            carrier_volume.disconnect()
        }
    }, [] )

    const setFrequency = value => {
        setFreq(value)
        oscillatorRef.current.frequency.setValueAtTime(value, context.currentTime)
    }

    const setVol = value => {
        carrier_volumeRef.current.gain.linearRampToValueAtTime(value * .01, context.currentTime)
        setVolume(value * .01)
    }

    return (<Box direction="column">
      <Grid container direction="row">
        <IconButton size="small" onClick={onRemove}>
            <RemoveCircle />
        </IconButton>
        <Knob skin={s10} unlockDistance={0} preciseMode={false} value={volume * 100} min={0} max={100} onChange={setVol} style={{height: '50px', marginBottom: '10px'}}  />
      </Grid>
      <Slider
        orientation="vertical"
        value={freq}
        marks={solfeggioMarks}
        min={minFreq}
        max={maxFreq}
        step={null}
        style={{ marginRight: 40, height: `calc(100% - 60px)` }}
        onChange={(evt,value) => {
          if (evt.type === 'mousedown') {
            onDragStart()
          }
          setFrequency(value)
        }}
        onChangeCommitted={(evt, value) => {
            onDragEnd()
            setFrequency(value)
        }}
      /></Box>)
}

SolfeggioSlider.propTypes = {
    classes: PropTypes.object.isRequired,
  }

  export default withStyles(styles)(SolfeggioSlider)