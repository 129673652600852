import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'

function ImageButton({
    classes,
    imgSrc,
    title,
    subtitle,
    style,
    className,
    onClick,
    bottomRight,
    topRight}) {

    return (<div onClick={onClick} className={[classes.container, className].join(' ')} style={{
        backgroundImage: `url(${imgSrc})`,
        ...style
    }}>
            <div className={classes.title}>{title}</div>
            <div className={classes.subtitle}>{subtitle}</div>

            { topRight && <div className={classes.topRight}>{topRight}</div>}
            { bottomRight && <div className={classes.bottomRight}>{bottomRight}</div>}
        </div>)
}

const styles = theme => ({
    container: {
        position: 'relative',
        height: 200,
        width: '100%',
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        color: 'white',
        padding: 5,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        cursor: 'pointer'
    },
    title: {
        fontSize: 18,
    },
    subtitle: {
        fontSize: 16,
    },
    topRight: {
        position: 'absolute',
        top: 5,
        right: 5
    },
    bottomRight: {
        position: 'absolute',
        bottom: 5,
        right: 5
    }
  })

  ImageButton.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ImageButton)