import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { firestore } from '../firebase'
import CourseCard from './CourseCard'
import { Link as RouterLink } from "react-router-dom"
import Link from '@material-ui/core/Link'
import LinearProgress from '@material-ui/core/LinearProgress'
import { LanguageContext } from '../Contexts/language-context'

function Courses(props) {
    const { classes } = props
    const [courses, setCourses] = useState(null)

    const language = useContext(LanguageContext)

    useEffect(() => {
        let unsubscribeFromCoursesListener = firestore.collection('courses')
        .where('language', '==', language)
        .onSnapshot(snap => {
            const courses = []
            snap.docChanges().forEach(change => {
                courses.push({...change.doc.data(), id: change.doc.id})
            })
            setCourses(courses)
        })
        return () => {
            unsubscribeFromCoursesListener()
        };
      }, [ language ])

    return (<div className={classes.container}>
        {courses ? courses.map(course => <Link component={RouterLink} key={course.id} to={{
            pathname: `/courses/${course.id}`,
            state: { course }
        }}><CourseCard course={course} /></Link>) : <LinearProgress variant="indeterminate" />}
        </div>)
}

const styles = theme => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(345px, 1fr))',
        gridGap: '1em'
    }
  })
Courses.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(Courses)