import 'react-circular-progressbar/dist/styles.css'
import 'rc-slider/assets/index.css'
import 'animate.css/animate.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-h5-audio-player/lib/styles.css'
import './App.css'

import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import React, { useState, useEffect } from 'react'
import { auth } from './firebase'
import { SnackbarProvider, useSnackbar } from 'notistack'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import SignIn from './SignIn'
import Dashboard from './Dashboard'

import * as moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#754D8A',
      main: '#28003D',
      dark: '#0F0024',
      contrastText: '#fff',
    },
    secondary: {
      light: '#FFFFA6',
      main: '#FFDD59',
      dark: '#B3910D',
      contrastText: '#000',
    }
  }
})

const styles = theme => ({
  App: {
    display: 'flex',
  },
})

function App() {

  // const { enqueueSnackbar } = useSnackbar()

  const [ initialised, setInitialised ] = useState(false)
  const [ signedIn, setSignedIn ] = useState(false)
  const [ user, setUser ] = useState(null)

  useEffect(() => {
    auth.onAuthStateChanged(user => {
        setUser(user)
        setInitialised(true)
        // setTimeout(() => {
        //   enqueueSnackbar('This is a success message!', { variant: 'success' })
        // }, 2000)
    })
  }, [])

  const signIn = event => {
    event.preventDefault()
    const email = document.getElementById('email').value
    const password = document.getElementById('password').value
    auth.signInWithEmailAndPassword(email, password)
  }

  const signUp = async event => {
    event.preventDefault()
    const email = document.getElementById('email').value
    const password = document.getElementById('password').value
    try {
      await auth.createUserWithEmailAndPassword(email, password)
    } catch (e) {
      alert(e.message)
    }
  }

    return (<SnackbarProvider maxSnack={3}>
      <MuiThemeProvider theme={theme}>
      {initialised ? <div className="App">
        <CssBaseline />
        { user ? <Dashboard user={user} />: <SignIn signIn={signIn} signUp={signUp}/> }
      </div>
      : <LinearProgress variant="indeterminate" />}</MuiThemeProvider></SnackbarProvider>)
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(App)