import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { firestore, storage } from '../firebase'
import { Typography, Slider, Box } from '@material-ui/core'
import Plyr from 'react-plyr'
import { Link as RouterLink, withRouter } from "react-router-dom"
import Link from '@material-ui/core/Link'
import CircularProgressPlayButton from './CircularProgressPlayButton'
import { Howl } from 'howler'
import LinearProgress from '@material-ui/core/LinearProgress'
import AudioPlayer from 'react-h5-audio-player'
import { SubscriptionContext } from '../Contexts/subscription-context'
import { withTranslation } from 'react-i18next'
import { UserDataCoursesContext } from '../Contexts/user-data-courses'

let guideAudioControl
let backgroundAudioControls = []
let progressCallback

function CourseDay(props, context) {
    const { classes, match, t, history } = props

    const subscription = useContext(SubscriptionContext)
    const userDataCourses = useContext(UserDataCoursesContext)

    const [courseDay, setCourseDay] = useState(null)
    const [ introVideoURL, setIntroVideoURL ] = useState(null)
    const [ introVideoPosterURL, setIntroVideoPosterURL ] = useState(null)
    const [ progress, setProgress ] = useState(0)
    // const [ guideURL, setGuideURL ] = useState(null)
    const [ allLoaded, setAllLoaded ] = useState(false)
    const [ introStaticImgURL, setIntroStaticImgURL ] = useState(null)
    const [ introAudioURL, setIntroAudioURL ] = useState(null)
    const [ backgroundVolumes, setBackgroundVolumes ] = useState(null)
    const [ backgroundPlayControls, setBackgroundPlayControls ] = useState(null)
    const [ backgroundSoundKeys , setBackgroundSoundKeys ] = useState([])

    useEffect(() => {
      let unsubscribeFromCourseDayListener
      const loadData = async data => {
        const { introVideoGS, introVideoPosterGS, guideGS, backgroundSounds, introStaticImgGS, introAudioGS } = data
        console.log('data', data.free, data, subscription)
        if (!data.free) {
          if (!subscription.active) {
            return alert('No active subscription found! Please make sure the account you are logged in with has an active subscription.')
          }
        }
        const dayNamesCompleted = userDataCourses[match.params.id] && userDataCourses[match.params.id].dayNamesCompleted
        console.log('dayNamesCompleted', dayNamesCompleted, 'userDataCourses', userDataCourses)
        //TODO: userDataCourses not initialised yet... Maybe need to block app until all stuff is loaded in Dashborad
        if (Object.keys(dayNamesCompleted).length < match.params.dayIdx) {
          history.push(match.url.split('/').slice(0, -1).join('/'))
          return alert("Erst vorgherignn TAg amchen") // and also navigate back...
        }
        if (introVideoGS) {
          setIntroVideoPosterURL(await storage.refFromURL(introVideoPosterGS).getDownloadURL())
          setIntroVideoURL(await storage.refFromURL(introVideoGS).getDownloadURL())
        } else {
          setIntroStaticImgURL(await storage.refFromURL(introStaticImgGS).getDownloadURL())
        }
        setIntroAudioURL(await storage.refFromURL(introAudioGS).getDownloadURL())
        //guideAudioControl = new Audio(await storage.refFromURL(guideGS).getDownloadURL())
        const backgroundSoundKeys = Object.keys(backgroundSounds)
        setBackgroundSoundKeys(backgroundSoundKeys)
        let numberOfAudioFilesToLoad = backgroundSoundKeys.length + 1
        const decrementNumberOfSoundsToLoadAndSetAllLoadedTrueIfReady = () => {
          numberOfAudioFilesToLoad--
          console.log(numberOfAudioFilesToLoad)
          if (numberOfAudioFilesToLoad === 0) {
            setAllLoaded(true)
          }
        }
        guideAudioControl = new Howl({
          src: await storage.refFromURL(guideGS).getDownloadURL(),
          onend: () => {
            props.history.push(`/courses/${match.params.id}`)
          }
        })
        guideAudioControl.once('load', decrementNumberOfSoundsToLoadAndSetAllLoadedTrueIfReady)
        
        const backgroundVolumes = []
        for (let k of backgroundSoundKeys) {
          console.log('KKKKKKKKKkkk', k)
          backgroundVolumes.push(.5)
          const backgroundAudioControl = new Howl({
            src: await storage.refFromURL(backgroundSounds[k].gs).getDownloadURL(),
            loop: true,
            volume: .5
          })
          backgroundAudioControls.push(backgroundAudioControl)
          backgroundAudioControl.once('load', decrementNumberOfSoundsToLoadAndSetAllLoadedTrueIfReady)
        }
        setBackgroundPlayControls(backgroundAudioControls)
        setBackgroundVolumes(backgroundVolumes)

        // backgroundAudioControls = backgroundSoundKeys.map(k => {
        //     const backgroundAudioControl = new Howl({
        //     src: await storage.refFromURL(backgroundSounds[k].gs).getDownloadURL()
        //   })
        //   backgroundAudioControl.once('load', decrementNumberOfSoundsToLoadAndSetAllLoadedTrueIfReady)
        //   return backgroundAudioControl
        // })


        //setAllLoaded(true) // <== Maybe only after all sounds have loaded???
        // guideAudioControl.play()
        // const guideURL = await storage.refFromURL(guideGS).getDownloadURL()
        // setIntroVideoURL(guideURL)
      }
      if (props.location && props.location.state && props.location.state.day) {
        console.log('1, seting cd: ', props.location.state.day)
        setCourseDay(props.location.state.day)
        loadData(props.location.state.day)
        unsubscribeFromCourseDayListener = () => {}
      } else {
        unsubscribeFromCourseDayListener = firestore.doc(`courses/${match.params.id}/extra-info/data`).onSnapshot(doc => {
          const days = doc.data().files
          console.log('days', days, 'match.params.id', match.params.id, 'data', doc.data())
          console.log('2, seting cd: ', days[match.params.dayIdx])
          setCourseDay(days[match.params.dayIdx])
          loadData(days[match.params.dayIdx])
        })
      }
        return () => {
            unsubscribeFromCourseDayListener()
        };
      }, [match.params.id, match.params.dayIdx, props.history, props.location, subscription]);

      const updateProgress = () => {
        // First check if laoded!!
        const dur = guideAudioControl.duration()
        const pos = guideAudioControl.seek()
        console.log('dur', dur, 'pos', pos)
        setProgress(pos / dur)
      }

      const play = () => {
        if(guideAudioControl.playing()) {
          // TODO: ani to pause
          guideAudioControl.pause()
          backgroundAudioControls.forEach(backgroundAudioControl => {
            backgroundAudioControl.pause()
          })
          clearInterval(progressCallback)
        } else {
          guideAudioControl.play()
          backgroundAudioControls.forEach(backgroundAudioControl => {
            backgroundAudioControl.play()
          })
          // TODO: Use request animation frame instead!!
          updateProgress()
          progressCallback = setInterval(updateProgress, 1000)
        }
      }

    return (<div>
        { allLoaded ? <div className={classes.container}>
        <div className={classes.topVideoContainer}>
        {
          introVideoURL ? <Plyr
            type="video"
            url={introVideoURL}
            poster={introVideoPosterURL}
          />
          :
          <img className={classes.topImage} src={introStaticImgURL} alt={t('Meditation image')} />
        }
          <AudioPlayer src={introAudioURL} />
        </div>
          
          <CircularProgressPlayButton progress={progress} playPausePress={play} className={classes.circularProgressPlayButton} />
          <Link component={RouterLink} className={classes.goBack} to={`/courses/${match.params.id}`}>&lt; back</Link>
          </div> : <LinearProgress variant="indeterminate" /> }

          {
            allLoaded && backgroundSoundKeys.map((bs,i) => <Box key={i} alignSelf="stretch">
                <Typography>{courseDay.backgroundSounds[bs].name}</Typography>
                <Slider min={0} max={1} step={0.01} value={backgroundVolumes[i]} onChange={(evt, value) => {
                    backgroundPlayControls[i].volume(value)
                    backgroundVolumes[i] = value
                    setBackgroundVolumes([...backgroundVolumes])
                }} />
                </Box>)
          }
        </div>)
}

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    circularProgressPlayButton: {
      width: 200,
      height: 200
    },
    goBack: {
      alignSelf: 'flex-start'
    },
    topVideoContainer: {
      maxWidth: 800,
      minWidth: '50%'
    },
    topImage: {
      width: '100%'
    },
  })

  CourseDay.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withTranslation()(withRouter(withStyles(styles)(CourseDay)))