import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { firestore, storage } from '../firebase'
import Plyr from 'react-plyr'
import { Link as RouterLink } from "react-router-dom"
import Link from '@material-ui/core/Link'
import LinearProgress from '@material-ui/core/LinearProgress'
import AudioPlayer from 'react-h5-audio-player'
import { Lock } from '@material-ui/icons'
import { SubscriptionContext } from '../Contexts/subscription-context'
import { UserDataCoursesContext } from '../Contexts/user-data-courses'
import { showSubscriptionAlert } from '../inter-component-comunication'
import { withTranslation } from 'react-i18next'

function Course(props) {
    const { classes, match, t } = props

    const subscription = useContext(SubscriptionContext)
    const userDataCourses = useContext(UserDataCoursesContext)

    const [course, setCourse] = useState(null)
    const [ extraInfo, setExtraInfo ] = useState(null)
    const [ introVideoURL, setIntroVideoURL ] = useState(null)
    const [ introVideoPosterURL, setIntroVideoPosterURL ] = useState(null)
    const [ introAudioURL, setIntroAudioURL ] = useState(null)
    const [ introStaticImgURL, setIntroStaticImgURL ] = useState(null)
    const [ isReady, setIsReady ] = useState(false)

    const dayNamesCompleted = userDataCourses[match.params.id] && userDataCourses[match.params.id].dayNamesCompleted
    
    useEffect(() => {
      // if above is undefined, need to load course too!!
        const unsubscribeFromCourseListener = firestore.doc(`courses/${match.params.id}`).onSnapshot(doc => {
              setCourse({...doc.data(), id: doc.id})
        })
        const unsubscribeFromExtraInfoListener = firestore.doc(`courses/${match.params.id}/extra-info/data`).onSnapshot(async doc => {
              const data = doc.data()
              setExtraInfo(data)
              const { introVideoGS, introVideoPosterGS, introAudioGS, introStaticImgGS } = data
              if (introVideoGS) {
                setIntroVideoPosterURL(await storage.refFromURL(introVideoPosterGS).getDownloadURL())
                setIntroVideoURL(await storage.refFromURL(introVideoGS).getDownloadURL())
              } else {
                setIntroStaticImgURL(await storage.refFromURL(introStaticImgGS).getDownloadURL())
              }
              setIntroAudioURL(await storage.refFromURL(introAudioGS).getDownloadURL())
              setIsReady(true)
        })
        return () => {
            unsubscribeFromCourseListener()
            unsubscribeFromExtraInfoListener()
        };
      }, [match.params.id])

    return (<div>
        {isReady ? <div className={classes.container}>
          <div className={classes.topVideoContainer}>
          { introVideoURL ? <Plyr
            type="video"
            url={introVideoURL}
            title={`${course.name} - Introduction Video`}
            poster={introVideoPosterURL}
            className={classes.topVideo}
          /> : <img className={classes.topImage} src={introStaticImgURL} alt={t('Meditation image')} />}
          <AudioPlayer src={introAudioURL} />
          </div>
          {
            extraInfo && extraInfo.files.map((day, idx) => <Link component={RouterLink} className={classes.day} onClick={evt => {
              if (!day.free) {
                if (!subscription.active) {
                  evt.preventDefault()
                  showSubscriptionAlert.on()
                }
              }
              if (Object.keys(dayNamesCompleted).length < idx) {
                evt.preventDefault()
                alert('Yo, must erst machen alter')
              }
            }} key={`${course.id}-${idx}`} to={{
              pathname: `/courses/${course.id}/${idx}`,
              state: { day, course }
            }}>{Object.keys(dayNamesCompleted).length <= idx ? ' ' : '✓'}{day.name} { subscription.active || day.free ? '' : <Lock />}</Link>)
          }
          </div> : <LinearProgress variant="indeterminate" />}
        </div>)
}

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    topVideoContainer: {
      maxWidth: 800,
      minWidth: '50%'
    },
    topImage: {
      width: '100%'
    },
    day: {
      padding: 10,
      fontSize: 20
    }
  })

Course.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withTranslation()(withStyles(styles)(Course))