import React, { useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
// import WebAudioTinySynth from 'webaudio-tinysynth'
import { Button } from '@material-ui/core'
import oscillators from 'web-audio-oscillators'
import Slider from 'rc-slider'
import { storage, firestore, auth, firebase } from '../firebase'
// import scriptLoader from 'react-async-script-loader'
import useScript from '../Hooks/use-script'

const context = new (window.AudioContext || window.webkitAudioContext)();
let oscillator
let oscillatorLeft
let oscillatorRight
// const panNodeLeft = context.createStereoPanner()
// panNodeLeft.pan.setValueAtTime(-1, context.currentTime)
// panNodeLeft.connect(context.destination)
const pannerLeft = context.createPanner()
    pannerLeft.panningModel = 'equalpower'
    pannerLeft.setPosition(-1, 0, 1 - Math.abs(-1))
    pannerLeft.connect(context.destination)
// const panNodeRight = context.createStereoPanner()
// panNodeRight.pan.setValueAtTime(1, context.currentTime)
// panNodeRight.connect(context.destination)
const pannerRight = context.createPanner()
pannerRight.panningModel = 'equalpower';
pannerRight.setPosition(1, 0, 1 - Math.abs(1))
pannerRight.connect(context.destination)

// const synth = new WebAudioTinySynth()

const SOLFEGGIO_FREQUENCIES = [
    396,
    417,
    528,
    639,
    741,
    852
]

const INSTRUMENTS = Object.keys(oscillators)

const BINAURAL_PATTERN_RANGES = [
    { name: 'Delta', which: 'is associated with sleepless dreams', min: 0.1, max: 4 },
    { name: 'Theta', which: 'is associated with sleep in the rapid eye movement or REM phase, meditation, and creativity', min: 4, max: 8 },
    { name: 'Alpha', which: 'may encourage relaxation', min: 8, max: 13 },
    { name: 'Beta', which: 'may help promote concentration and alertness', min: 14, max: 100 }
]

const INITAL_BINAURAL_FREQUEINCY_DIFFERENCE = 2
const LEFT_BINAURAL_START_FREQUENCY = 440
const RIGHT_BINAURAL_START_FREQUENCY = LEFT_BINAURAL_START_FREQUENCY + INITAL_BINAURAL_FREQUEINCY_DIFFERENCE

// const PAYPAL_CLIENT_ID = 'sb'
const PAYPAL_CLIENT_ID = 'Ae3wHu7R3R2g0FVd-jwTxUv15Q4pVdwwmumon9fZ8usnm8xWHQEbVwA5MP5mKXMOoZiAYlv1AF6OY2UB'

function Lab(props) {
    const { classes } = props

    const [ payPalScriptLoaded, payPalScriptError] = useScript(
        `https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&vault=true`,
        () => {
            window.paypal.Buttons({

                createSubscription: function(data, actions) {
              
                  return actions.subscription.create({
              
                    'plan_id': 'P-96V47242KB258180GLV5K3YA'
              
                  });
              
                },
              
              
                onApprove: function(data, actions) {
                    //TODO: Write data.subscriptionID to user-data/{uid}/subscr..
                    console.log('data', data)
                //   alert('You have successfully created subscription ' + data.subscriptionID);
                    firestore.collection(`user-data/${auth.currentUser.uid}/paypal-subscriptions`)
                    .add({
                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                        ...data
                    })
                    console.log('Written to db')
                    alert('Subscription success! Please allow up to a minute...')
                    // return actions.
                    //TODO: Error message for not returning anything, but in demo nothing is returned and I can't find documentation
                }
              
              
              }).render('#paypal-button-container-yearly')
            window.paypal.Buttons({
                createSubscription: function(data, actions) {
                  return actions.subscription.create({
                    'plan_id': 'P-3HR244462H356513RLZBLUNI'
                  })
                },
                onApprove: function(data, actions) {
                    firestore.collection(`user-data/${auth.currentUser.uid}/paypal-subscriptions`)
                    .add({
                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                        ...data
                    })
                    alert('Subscription success! Please allow up to a minute...')
                }
              }).render('#paypal-button-container-monthly')
        }
      )

    const [ freq, setFreq ] = useState(SOLFEGGIO_FREQUENCIES[0])
    const [ instrument, setInstrument ] = useState(INSTRUMENTS[0])

    const [ freqLeft, setFreqLeft ] = useState(LEFT_BINAURAL_START_FREQUENCY)
    const [ freqRight, setFreqRight ] = useState(RIGHT_BINAURAL_START_FREQUENCY)
    const [ isBinauralPlaying, setIsBinauralPlaying ] = useState(false)
    const [ currentBinauralRange, setCurrentBinauralRange ] = useState(BINAURAL_PATTERN_RANGES[0])

    const playSound = () => {
        oscillator = oscillators[instrument](context)
        oscillator.frequency.value = freq
        oscillator.connect(context.destination)
        oscillator.start()
    }
    
    const stopSound = () => {
        oscillator.stop()
    }

    const startBinaurals = () => {
        oscillatorLeft = oscillators.sine(context)
        console.log(oscillatorLeft)
        oscillatorLeft.frequency.value = freqLeft
        // oscillatorLeft.connect(panNodeLeft)
        oscillatorLeft.connect(pannerLeft)
        oscillatorLeft.start()
        oscillatorRight = oscillators.sine(context)
        oscillatorRight.frequency.value = freqRight
        // oscillatorRight.connect(panNodeRight)
        oscillatorRight.connect(pannerRight)
        oscillatorRight.start()
    }
    
    const stopBinaurals = () => {
        oscillatorLeft.stop()
        oscillatorRight.stop()
    }

    const toggleBinauralPlaying = () => {
        setIsBinauralPlaying(!isBinauralPlaying)
        if (isBinauralPlaying) {
            stopBinaurals()
        } else {
            startBinaurals()
        }
    }

    const updateCurrentBinauralRange = () => {
        const freqDiff = Math.abs(freqLeft - freqRight)
        setCurrentBinauralRange(BINAURAL_PATTERN_RANGES.filter(bpr => bpr.min < freqDiff && bpr.max >= freqDiff)[0])
    }

    const onLeftBinauralFrequencyChange = frq => {
        setFreqLeft(frq)
        updateCurrentBinauralRange()
        if (isBinauralPlaying) {
            oscillatorLeft.frequency.value = frq
        }
    }
    
    const onRightBinauralFrequencyChange = frq => {
        setFreqRight(frq)
        updateCurrentBinauralRange()
        if (isBinauralPlaying) {
            oscillatorRight.frequency.value = frq
        }
    }

    const downloadSecureFile = async () => {
        try {
            const dlLink = await storage.refFromURL('gs://myditation-65ded.appspot.com/test/test.txt').getDownloadURL()
            console.log('dlLink', dlLink)
        } catch (e) {
            console.error(e)
        }
    }

    return ( <div className={classes.container}>
        <h1>Lab</h1>
        <h2>Solfeggios</h2>
        <Button onMouseDown={playSound} onMouseUp={stopSound}>Play sound</Button>
        <div>
        {
            SOLFEGGIO_FREQUENCIES.map(f => <Button key={`f${f}`} color={freq === f ? 'primary' : 'default'} onClick={() => {
                setFreq(f)
            }}>{f}Hz</Button>)
        }
        </div>
        <div>
        {
            INSTRUMENTS.map(i => <Button key={`i${i}`}
            color={instrument === i ? 'primary' : 'default'}
            onClick={() => {
                setInstrument(i)
            }}>{i}</Button>)
        }
        </div>

        <h2>Binaurals</h2>
        <Button onClick={toggleBinauralPlaying}>{isBinauralPlaying ? 'STOP' : 'START'} Binaural Beat</Button>
        <div>Binaural frequency {Math.abs(freqLeft - freqRight)}Hz {currentBinauralRange ? `(in ${currentBinauralRange.name} range, which ${currentBinauralRange.which})` : null }</div>
        <div className={classes.binauralGrid}>
        <div className={classes.binauralGridItem}>
            <Slider  min={20} max={20000} value={freqLeft} onChange={onLeftBinauralFrequencyChange} />
            <span>{freqLeft}Hz</span>
        </div>
        <div className={classes.binauralGridItem}>
            <Slider  min={20} max={20000} value={freqRight} onChange={onRightBinauralFrequencyChange} />
            <span>{freqRight}Hz</span>
        </div>
        </div>

        <h2>PayPal</h2>
        {
            payPalScriptLoaded && !payPalScriptError ? 'Srcipt loaded' : 'Loading script...'
        }
        <div className={classes.paypalButtons}>
            <div>
                <h3>Yealry</h3>
                <div id="paypal-button-container-yearly"></div>
            </div>
            <div>
                <h3>Monthly</h3>
                <div id="paypal-button-container-monthly"></div>
            </div>
        </div>
        

        <h2>Test secure files</h2>
        <Button onClick={downloadSecureFile}>test dl</Button>

        <h2>Info</h2>
        <div>uid: {auth.currentUser.uid}</div>
        </div>)
}

const styles = theme => ({
    container: {
        position: 'relative',
        height: '100%',
        width: '100%'
    },
    binauralGrid: {
        display: 'flex'
    },
    binauralGridItem: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10
    },
    paypalButtons: {
        display: 'flex',
        flexDirection: 'row'
    }
  })

  Lab.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Lab)