import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { firestore, storage } from '../firebase'
import { Link as RouterLink, withRouter } from "react-router-dom"
import Link from '@material-ui/core/Link'
import CircularProgressPlayButton from './CircularProgressPlayButton'
import { Howl } from 'howler'
import LinearProgress from '@material-ui/core/LinearProgress'

let guideAudioControl
let backgroundAudioControls = []
let progressCallback

function JourneySession(props) {
    const { classes, match } = props
    const [ journeySession, setJourneySession ] = useState(null)
    const [ progress, setProgress ] = useState(0)
    const [ allLoaded, setAllLoaded ] = useState(false)
    useEffect(() => {
      let unsubscribeFromJourneySessionListener
      const loadData = async data => {
        const { guideGS, backgroundSounds } = data
        const backgroundSoundKeys = Object.keys(backgroundSounds)
        let numberOfAudioFilesToLoad = backgroundSoundKeys.length + 1
        const decrementNumberOfSoundsToLoadAndSetAllLoadedTrueIfReady = () => {
          numberOfAudioFilesToLoad--
          console.log(numberOfAudioFilesToLoad)
          if (numberOfAudioFilesToLoad === 0) {
            setAllLoaded(true)
          }
        }
        guideAudioControl = new Howl({
          src: await storage.refFromURL(guideGS).getDownloadURL(),
          onend: () => {
            props.history.push(`/journeys/${match.params.id}`)
          }
        })
        guideAudioControl.once('load', decrementNumberOfSoundsToLoadAndSetAllLoadedTrueIfReady)

        for (let k of backgroundSoundKeys) {
          const backgroundAudioControl = new Howl({
            src: await storage.refFromURL(backgroundSounds[k].gs).getDownloadURL(),
            loop: true
          })
          backgroundAudioControls.push(backgroundAudioControl)
          backgroundAudioControl.once('load', decrementNumberOfSoundsToLoadAndSetAllLoadedTrueIfReady)
        }
      }
      if (props.location && props.location.state && props.location.state.session) {
        setJourneySession(props.location.state.session)
        loadData(props.location.state.session)
        unsubscribeFromJourneySessionListener = () => {}
      } else {
        unsubscribeFromJourneySessionListener = firestore.doc(`journeys/${match.params.id}/extra-info/data`).onSnapshot(doc => {
          const sessions = doc.data().files
          console.log('match.params.id', match.params.id, 'data', doc.data())
          setJourneySession(sessions[match.params.sessionIdx])
          loadData(sessions[match.params.sessionIdx])
        })
      }
        return () => {
          clearInterval(progressCallback)
          unsubscribeFromJourneySessionListener()
          // Unooad all howlers!!
          guideAudioControl && guideAudioControl.unload()
          backgroundAudioControls.forEach(bckc => {
            bckc.unload()
          })
        };
      }, [match.params.id, match.params.sessionIdx, props.history, props.location]);

      const updateProgress = () => {
        // First check if laoded!!
        const dur = guideAudioControl.duration()
        const pos = guideAudioControl.seek()
        console.log('dur', dur, 'pos', pos)
        setProgress(pos / dur)
      }

      const play = () => {
        if(guideAudioControl.playing()) {
          // TODO: ani to pause
          guideAudioControl.pause()
          backgroundAudioControls.forEach(backgroundAudioControl => {
            backgroundAudioControl.pause()
          })
          clearInterval(progressCallback)
        } else {
          guideAudioControl.play()
          backgroundAudioControls.forEach(backgroundAudioControl => {
            backgroundAudioControl.play()
          })
          // TODO: Use request animation frame instead!!
          updateProgress()
          progressCallback = setInterval(updateProgress, 1000)
        }
      }

    return (<div>
        { allLoaded ? <div className={classes.container}>
          <CircularProgressPlayButton progress={progress} playPausePress={play} className={classes.circularProgressPlayButton} />
          <Link component={RouterLink} className={classes.goBack} to={`/journeys/${match.params.id}`}>&lt; back</Link>
          </div> : <LinearProgress variant="indeterminate" /> }
        </div>)
}

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    circularProgressPlayButton: {
      width: 200,
      height: 200
    },
    goBack: {
      alignSelf: 'flex-start'
    }
  })

  JourneySession.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withRouter(withStyles(styles)(JourneySession))