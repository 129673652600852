import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from "react-router-dom"

import { LanguageContext } from '../Contexts/language-context'
import { withTranslation } from 'react-i18next'
import { firestore, storage } from '../firebase'
import { Howl } from 'howler'

import CircularProgressPlayButton from './CircularProgressPlayButton'
import { Slider, Box, Typography } from '@material-ui/core';

const getLanguageKey = (item, lan) => {
    if (item.texts[lan]) {
        return lan
    } else {
        const defaultLan = Object.keys(item.texts)[0]
        return defaultLan
    }
}

function LibraryMeditation(props) {
    const {
        classes,
        // t
    } = props
    const language = useContext(LanguageContext)

    const [ready, setReady] = useState(false)
    const [meditation, setMeditation] = useState({})
    const [meditationSound, setMeditationSound] = useState(null)
    const [backgroundSounds, setBackgroundSounds] = useState([])
    const [backgroundSoundsVolumes, setBackgroundSoundsVolumes] = useState([])
    const [isPlaying, setIsPlaying] = useState(false)
    const [progress, setProgress] = useState(0)
    const [ languageKey, setLanguageKey ] = useState(null)

    useEffect(() => {
        const { dbPath } = props
        let progressUpdater
        const unsubscribeFromListener = firestore.doc(dbPath).onSnapshot(async doc => {
            const data = doc.data()
            setLanguageKey(getLanguageKey(data, language))
            setMeditation(data)
            const meditationSound = new Howl({
                src: await storage.refFromURL(data.fileGS).getDownloadURL(),
                autoplay: false,
                onplay: () => {
                  setIsPlaying(true)
                  progressUpdater = setInterval(() => {
                      setProgress(meditationSound.seek() / meditationSound.duration())
                  }, 250)
                },
                onpause: () => {
                  setIsPlaying(false)
                  clearInterval(progressUpdater)
                }
            })
            setMeditationSound(meditationSound)
            // TODO: Now backgorund souns!
            const backgroundSounds = []
            for (const sp of data.soundsPresets) {
                backgroundSounds.push(new Howl({
                    src: await storage.refFromURL(sp.gs).getDownloadURL(),
                    autoplay: false,
                    volume: .5
                }))
                backgroundSoundsVolumes.push(.5)
            }
            setBackgroundSounds(backgroundSounds)
            setBackgroundSoundsVolumes(backgroundSoundsVolumes)
            setReady(true)
        })
        return () => {
            unsubscribeFromListener()
            clearInterval(progressUpdater)
        }
    }, [ props.dbPath ])

    return (<div className={classes.container} style={{backgroundImage: `url(${meditation.imgUrl})`}}>
        <Typography variant="h1">{meditation.texts && meditation.texts[languageKey].name}</Typography>
        <Box flexGrow={1}/>
        <CircularProgressPlayButton progress={progress} playing={isPlaying} ready={ready} playPausePress={() => {
            if (isPlaying) {
                meditationSound.pause()
                backgroundSounds.forEach(bs => {
                    bs.pause()
                })
                setIsPlaying(false)
            } else {
                meditationSound.play()
                backgroundSounds.forEach(bs => {
                    bs.play()
                })
                setIsPlaying(true)
            }
        }} />
        <Box flexGrow={1}/>
        {
            ready && meditation.soundsPresets.map((sp, idx) => <Box className={classes.sliderContainer} key={idx}>
            <Typography>
                {sp.name}
            </Typography>
            <Slider min={0} max={1} step={0.01} value={backgroundSoundsVolumes[idx]} onChange={(evt, value) => {
                backgroundSounds[idx].volume(value)
                backgroundSoundsVolumes[idx] = value
            }} /></Box>)
        }
        </div>)
}

const styles = theme => ({
    container: {
        position: 'relative',
        height: 'calc(100% - 64px)',
        width: '100%',
        paddingTop: 5,
        color: 'white',
        backgroundSize: 'cover',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    imgButton: {
        margin: 5
    },
    sliderContainer: {
        alignSelf: 'stretch'
    }
  })

  LibraryMeditation.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withTranslation()(withStyles(styles)(LibraryMeditation)))