import React, { useState, useEffect, useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { firestore } from '../firebase'
import { default as Carousel } from "react-slick"
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import { List, ListItem, ListItemText, IconButton, Typography, Fab, SvgIcon } from '@material-ui/core'
import { ChevronRight, ChevronLeft, ExpandMore, Lock } from '@material-ui/icons'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import uuid from 'uuid/v4'
import { SubscriptionContext } from '../Contexts/subscription-context'
import { showSubscriptionAlert } from '../inter-component-comunication'

function MYDitateIcon(props) {
  return <SvgIcon {...props} viewBox="0 0 1536 1536">
      <path d="M950.54,1171.27c-79.92,0-132.22,55.25-182.54,105.58-47.36-47.36-101.63-105.58-174.65-105.58-86.83,0-150,68.08-150,146,0,84.86,59.2,146,142.08,146,79.92,0,132.22-55.25,182.54-105.57,47.36,47.36,101.63,105.57,174.65,105.57,86.83,0,150-68.08,150-146C1092.62,1232.45,1034.41,1171.27,950.54,1171.27ZM589.41,1404.13c-48.35,0-83.87-36.51-83.87-86.83,0-46.37,37.49-86.83,86.83-86.83,57.23,0,97.68,51.31,136.16,86.83C691,1355.78,645.65,1404.13,589.41,1404.13Zm354.22,0c-57.22,0-97.68-51.31-136.16-86.83,37.49-38.48,82.88-86.83,139.12-86.83,48.35,0,83.87,36.51,83.87,86.83C1030.46,1363.68,993,1404.13,943.63,1404.13Zm39.25-611.37C945.17,731.05,884,659.31,791,556.25l-21.67-24.61-21.68,24.61c-92.95,103.06-154.14,174.8-191.86,236.51-36.61,59.9-51.55,110.9-51.55,176,0,51.67,14.78,100.26,41.62,136.82,28,38.16,66,59.18,106.87,59.18,30.56,0,58.54-10.56,83.16-31.4,13-11,23.85-23.7,33.44-36.74,9.58,13,20.42,25.72,33.44,36.74,24.61,20.84,52.59,31.4,83.15,31.4,40.9,0,78.86-21,106.87-59.18,26.84-36.56,41.62-85.15,41.62-136.82C1034.43,903.66,1019.5,852.66,982.88,792.76Zm-96.94,297.69c-38.18,0-60.12-27.82-84.16-64.67l-16-86.31-34.11.22-14.82,86.19c-24,36.8-46,64.57-84.1,64.57-46.7,0-84.69-54.57-84.69-121.65,0-49.73,11.83-88.14,42.21-137,31.7-51,84.57-113.48,159.08-196.42,74.5,82.94,127.37,145.4,159.08,196.42,30.37,48.88,42.2,87.29,42.2,137C970.63,1035.88,932.64,1090.45,885.94,1090.45Zm-20-944.74c-24.22-47.1-58.73-73-97.15-73s-72.93,25.94-97.16,73c-21,40.76-32.51,94.3-32.51,150.74s11.55,110,32.51,150.74c24.23,47.1,58.73,73,97.16,73s72.93-25.94,97.15-73c21-40.76,32.51-94.3,32.51-150.74S886.86,186.47,865.9,145.71Zm-53.37,274c-13,25.34-29.4,40.47-43.78,40.47S738,445.08,725,419.74c-16.43-32-25.86-76.9-25.86-123.29s9.43-91.33,25.86-123.3c13-25.34,29.4-40.47,43.79-40.47s30.74,15.13,43.78,40.47c16.44,32,25.86,76.91,25.86,123.3S829,387.78,812.53,419.74Z" />
   </SvgIcon>
}

const sliderSettings = {
  dots: false,
  arrows: false,
  infinite: false
}

const isItemAccessibleByAccount = (item, subscription) => {
  if (!(item.free || subscription.active)) {
      showSubscriptionAlert.on()
      return false
  }
  return true
}

function MeditationCreator(props) {
    const { classes, history } = props

    const subscription = useContext(SubscriptionContext)

    const sliderRef = useRef(null)
    const [ activeStep, setActiveStep ] = useState(0)
    const [ openTopicPanel, setOpenTopicPanel ] = useState(null)
    const [ openBackgroundPanel, setOpenBackgroundPanel ] = useState(null)
    const [ selectedIntroduction, setSelectedIntroduction ] = useState(null)
    const [ pause1, setPause1 ] = useState(0)
    const [ selectedTopics, setSelectedTopics ] = useState([])
    const [ pause2, setPause2 ] = useState(0)
    const [ selectedEnding, setSelectedEnding ] = useState(null)
    const [ selectedBackgrounds, setSelectedBackgrounds ] = useState([])
    // const [ customMeditation, setCustomMeditation ] = useState({
    //   introduction: [],
    //   topics: [],
    //   pause1: {},
    //   ending: [],
    //   pause2: {},
    //   backgrounds: []
    // })


    const [introductions, setIntroductions] = useState([])
    const [topics, setTopics] = useState([])
    const [topicsContent, setTopicsContent] = useState({})

    const [endings, setEndings] = useState([])

    const [backgrounds, setBackgrounds] = useState([])
    const [backgroundsContent, setBackgroundsContent] = useState([])

    const steps = [
      { name: 'Introduction', completed: selectedIntroduction !== null, label: selectedIntroduction ? selectedIntroduction.name : null },
      { name: 'Pause 1', completed: pause1 > 0, label: pause1 > 0 ? pause1 : null },
      { name: 'Topics', completed: selectedTopics.length > 0, label: selectedTopics.map(t => t.name).join(', ') },
      { name: 'Pause 2', completed: pause2 > 0, label: pause2 > 0 ? pause2 : null },
      { name: 'Ending', completed: selectedEnding !== null, label: selectedEnding ? selectedEnding.name : null },
      { name: 'Background Sounds', completed: selectedBackgrounds.length > 0, label: selectedBackgrounds.map(b => b.name).join(', ') }
    ]

    useEffect(() => {
        // setTimeout(() => {
        //   console.log('jacihing')
        //   setCustomMeditation({
        //     ...customMeditation,
        //     introduction: [...customMeditation.introduction, 'ja']
        //   })
        // }, 2000)
        const loadIntroductions = async () => {
          const col = await firestore.collection('myditations/sections/introductions').get()
          const tmp = []
          col.forEach(doc => {
            tmp.push({...doc.data(), id: doc.id})
          })
          setIntroductions(tmp)
        }
        loadIntroductions()

        const loadTopics = async () => {
          const topicsCol = await firestore.collection('myditations/sections/topics').get()
          const numberOfTopics = topicsCol.docs.length
          let topicsLoaded = 0
          const tops = []
          let topsCont
          topicsCol.docs.forEach(doc => {
            // const { name } = doc.data()
            // tops.push(name)
            const { id } = doc
            tops.push(id)
            firestore.collection(`myditations/sections/topics/${id}/list`)
            .get()
            .then(snap => {
              topsCont = {[id]: []}
              snap.docs.forEach(doc => {
                topsCont[id].push({ ...doc.data(), id: doc.id })
              })
              const topicsContentNew = Object.assign(topicsContent, topsCont)
              if (++topicsLoaded === numberOfTopics) {
                // this.setState({ topicsContent, topics: tops })
                setTopics(tops)
                setTopicsContent(topicsContentNew)
              }
            })
            .catch(({ message, code }) => {
              alert(message)
            })
          })
        }
        loadTopics()

        const loadEndings = async () => {
          const col = await firestore.collection('myditations/sections/endings').get()
          const tmp = []
          col.forEach(doc => {
            tmp.push({...doc.data(), id: doc.id})
          })
          setEndings(tmp)
        }
        loadEndings()

        const loadBackgrounds = async () => {
          const backgroundsCol = await firestore.collection('myditations/sections/backgrounds').get()
          const numberOfBackgrounds = backgroundsCol.docs.length
          let backgroundsLoaded = 0
          const backs = []
          let backsCont
          backgroundsCol.docs.forEach(doc => {
            const { id } = doc
            backs.push(id)
            firestore.collection(`myditations/sections/backgrounds/${id}/list`)
            .get()
            .then(snap => {
              backsCont = {[id]: []}
              snap.docs.forEach(doc => {
                backsCont[id].push({ ...doc.data(), id: doc.id })
              })
              const backsContentNew = Object.assign(backgroundsContent, backsCont)
              if (++backgroundsLoaded === numberOfBackgrounds) {
                // this.setState({ topicsContent, topics: tops })
                setBackgrounds(backs)
                setBackgroundsContent(backsContentNew)
              }
            })
            .catch(({ message, code }) => {
              alert(message)
            })
          })
        }
        loadBackgrounds()

        return () => {
        };
      }, []);

      const advanceStep = () => {
        sliderRef.current.slickNext()
        setActiveStep(activeStep + 1)
      }

      const goToStep = idx => {
          sliderRef.current.slickGoTo(idx)
          setActiveStep(idx)
      }

      const startMeditation = () => {
        const steps = selectedIntroduction ? [{ type: 'introduction', item: selectedIntroduction }] : []

        if (pause1 > 0) {
          steps.push({
            type: 'pause',
            item: pause1
          })
        }

        if (selectedTopics.length) {
          selectedTopics.forEach(topic => {
            steps.push({
              type: 'topic',
              item: topic
            })
          })
        }
    
        if (selectedEnding) {
          steps.push({
            type: 'ending',
            item: selectedEnding
          })
        }
    
        if (pause2 > 0) {
          steps.push({
            type: 'pause',
            item: pause2
          })
        }
        const meditation = {
          steps,
          backgrounds: selectedBackgrounds,
        }
        const meditationTempId = uuid()
        console.log('START', meditation, meditationTempId)
        localStorage.setItem(meditationTempId, JSON.stringify(meditation))
        history.push(`/custom-meditation/${meditationTempId}`)
      }

      const onTopicPanelClick = name => {
        setOpenTopicPanel(name === openTopicPanel ? null : name)
      }
     
      const onBackgroundPanelClick = name => {
        setOpenBackgroundPanel(name === openBackgroundPanel ? null : name)
      }

    return (<div className={classes.container}>
      <Stepper nonLinear activeStep={activeStep}>
        {
          steps.map((step, idx) => <Step key={step.name}>
            <StepButton onClick={() => {goToStep(idx)}} completed={step.completed}
            optional={step.label}>
              {step.name}
            </StepButton>
          </Step>)
        }
      </Stepper>
      <Carousel {...sliderSettings} ref={sliderRef} >
          <div className={classes.slide}>
            <List className={classes.list}>
              {
                introductions.map(item => <ListItem key={item.id}>
                  <ListItemText primary={item.name}
                  onClick={() => {
                    if (!isItemAccessibleByAccount(item, subscription)) {
                      return
                    }
                    setSelectedIntroduction(item)
                    advanceStep()
                  }} />
                  { !(item.free || subscription.active) ? <Lock/> : null }
                  </ListItem>)
              }
            </List>
          </div>
          <div>
              <div className={classes.pauseSlide}>
                <IconButton size="medium" className={classes.huge} onClick={() => {
                  setPause1(Math.max(0, pause1 - 5))
                }}>
                  -
                </IconButton>
                <Typography className={classes.huge}>{pause1}</Typography>
                <IconButton size="medium" className={classes.huge} onClick={() => {
                  setPause1(pause1 + 5)
                }}>
                  +
                </IconButton>
              </div>
          </div>
          <div>
          {
            topics.map(topic => <ExpansionPanel key={topic} expanded={openTopicPanel === topic} onChange={() => {
              onTopicPanelClick(topic)
            }}>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <Typography className={classes.heading}>{topic}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
            <List className={classes.list}>
            {
              topicsContent[topic].map(item => <ListItem key={item.id}>
                <ListItemText primary={item.name}
                onClick={() => {
                  if (!isItemAccessibleByAccount(item, subscription)) {
                    return
                  }
                  setSelectedTopics([...selectedTopics, item])
                }} />
                { !(item.free || subscription.active) ? <Lock/> : null }
                </ListItem>)
            }
            </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>)
          }
          </div>
          <div>
          <div className={classes.pauseSlide}>
          <IconButton size="medium" className={classes.huge} onClick={() => {
            setPause2(Math.max(0, pause2 - 5))
          }}>
            -
          </IconButton>
          <Typography className={classes.huge}>{pause2}</Typography>
          <IconButton size="medium" className={classes.huge} onClick={() => {
            setPause2(pause2 + 5)
          }}>
            +
          </IconButton>
        </div>
          </div>
          <div>
          <div className={classes.slide}>
            <List className={classes.list}>
              {
                endings.map(item => <ListItem key={item.id}>
                  <ListItemText primary={item.name}
                  onClick={() => {
                    if (!isItemAccessibleByAccount(item, subscription)) {
                      return
                    }
                    setSelectedEnding(item)
                    advanceStep()
                  }} />
                  { !(item.free || subscription.active) ? <Lock/> : null }
                  </ListItem>)
              }
            </List>
          </div>
          </div>
          {/* BACKGPRUNDS */}
          <div>
          {
            backgrounds.map(background => <ExpansionPanel key={background} expanded={openBackgroundPanel === background} onChange={() => {
              onBackgroundPanelClick(background)
            }}>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <Typography className={classes.heading}>{background}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
            <List className={classes.list}>
            {
              backgroundsContent[background].map(item => <ListItem key={item.id}>
                <ListItemText primary={item.name}
                onClick={() => {
                  if (!isItemAccessibleByAccount(item, subscription)) {
                    return
                  }
                  setSelectedBackgrounds([...selectedBackgrounds, item])
                }} />
                { !(item.free || subscription.active) ? <Lock/> : null }
                </ListItem>)
            }
            </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>)
          }
          </div>
        </Carousel>
        <Fab onClick={activeStep < 5 ? advanceStep : startMeditation}
        variant="extended"
        aria-label="next"
        className={classes.nextFAB}>
          { activeStep < 5 ? 'Next' : 'Start' }
          { activeStep < 5 ? <ChevronRight /> : <MYDitateIcon/> }
        </Fab>
        <Fab onClick={advanceStep}
        variant="extended"
        aria-label="back"
        className={classes.backFAB}>
          <ChevronLeft/>
          Back
        </Fab>
        </div>)
}

const styles = theme => ({
    container: {
      // height: 'calc(100vh - 100px)'
    },
    slide: {
      height: 'calc(100vh - 100px)'
    },
    pauseSlide: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'calc(100vh - 100px)'
    },
    list: {
      height: 'calc(100vh - 200px)',
      overflowY: 'scroll'
    },
    huge: {
      fontSize: 100,
      margin: 5
    },
    nextFAB: {
      position: 'absolute',
      bottom: '1em',
      right: '1em'
    },
    backFAB: {
      position: 'absolute',
      bottom: '1em',
      left: '1em'
    }
  })
  MeditationCreator.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.any
}
export default withStyles(styles)(MeditationCreator)