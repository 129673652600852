import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import ImageButton from './ImageButton'
import { Link as RouterLink, withRouter } from "react-router-dom"
import Link from '@material-ui/core/Link'
import LinearProgress from '@material-ui/core/LinearProgress'

import { firestore } from '../firebase'
import { withTranslation } from 'react-i18next'
import { LanguageContext } from '../Contexts/language-context'

function LibraryCategories(props) {
    const { classes, match, dbPath } = props
    const [ items, setItems ] = useState(null)
    const language = useContext(LanguageContext)

    // const loadMeditations = async () => {
    //     const doc = await firestore.collection()
    // }

    useEffect(() => {
        // console.log(match, match.path === "/library/meditations")
        // // TODO: Change to dbPath parameter in <Route> !!!
        // if (match.path === '/library/meditations') {
        //     let tmp = []
        //     itemsListener = firestore.collection('library/meditations/items')
        //     .onSnapshot(snap => {
        //         snap.docChanges().forEach(change => {
        //             tmp.push({...change.doc.data(), id: change.doc.id})
        //         })
        //         setItems(tmp)
        //     })
        // }
        let unsubscribeFromItemsListener
        const getData = async () => {
            const categoryDoc = await firestore.doc(dbPath.slice(0, -6)).get()
            const { noLanguage } = categoryDoc.data()
            // console.log(noLanguage)
            let tmp = []
            let query = firestore.collection(dbPath)
            if (!noLanguage) {
                query = query.where('language', '==', language)
            }
            unsubscribeFromItemsListener = query
            .onSnapshot(snap => {
                snap.docChanges().forEach(change => {
                    tmp.push({...change.doc.data(), id: change.doc.id})
                })
                setItems(tmp)
            })
        }
        getData()
        return () => {
            unsubscribeFromItemsListener()
        };
    }, [ dbPath, language ])

    return items ? (<div className={classes.container}>
        {
            items.map(item => <Link component={RouterLink} key={item.id} to={`${match.path}/${item.id}`}><ImageButton
                imgSrc={item.imgUrl}
                title={item.texts ? item.texts[language].name : item.name}
                className={classes.item} /></Link>)
        }
        </div>) : <LinearProgress variant="indeterminate" />
}

const styles = theme => ({
    container: {
        position: 'relative',
        height: '100%',
        width: '100%'
    },
    item: {
        margin: 5
    }
  })

  LibraryCategories.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withTranslation()(withStyles(styles)(LibraryCategories)))