import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { firestore, auth } from '../firebase'
import { withRouter } from "react-router-dom"
import InstructorTable from './InstructorTable'

function InstructorSession(props) {
    const { classes } = props
    const [instructors, setInstructors] = useState([])
    let unsubscribeFromOnlineInstructorListener

    const onInstructorTableChatPress = (instructor) => {
      const { uid } = auth.currentUser
      props.history.push(`/instructor-session/chat/${[uid, instructor.uid].sort().join('-')}`)
    }
    const onInstructorTableCallPress = () => {}

    useEffect(() => {
        unsubscribeFromOnlineInstructorListener = firestore.collection(`user-data`)
        .where('isInstructor', '==', true)
        .onSnapshot(snap => {
          let tmpInstructors = [...instructors]
          snap.docChanges().forEach(change => {
            if (change.type === 'added') {
              const data = change.doc.data()
              tmpInstructors.push({...data, uid: change.doc.id})
              //setInstructors([...instructors, {...data, uid: change.doc.id}])
            } else if (change.type === 'modified') {
              // setInstructors(
                tmpInstructors.map(instructor => {
                  if (instructor.uid !== change.doc.id) {
                    return instructor
                  }
                  return ({...change.doc.data(), uid: change.doc.id})
                })
              // )
            }
          })
          setInstructors(tmpInstructors)
        })
        return () => {
          unsubscribeFromOnlineInstructorListener()
        };
      }, []);

    return (<div className={classes.container}>
      <InstructorTable data={instructors}
        onChatPress={onInstructorTableChatPress}
        onCallPress={onInstructorTableCallPress} />
        </div>)
}

const styles = theme => ({
    container: {
        
    }
  })
  InstructorSession.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withRouter(withStyles(styles)(InstructorSession))