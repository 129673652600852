import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types'
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import { 
  Chat as ChatIcon,
  Videocam,
  Phone
} from '@material-ui/icons'

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
}

function InstructorTable(props) {
  const { classes, data } = props
    console.log('data', data)
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Uid</TableCell>
            <TableCell>Chat</TableCell>
            <TableCell>Call</TableCell>
            <TableCell align="right">Online status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(n => {
            return (
              <TableRow key={n.uid}>
                <TableCell component="th" scope="row">
                  {n.name}
                </TableCell>
                
                <TableCell>
                  {n.uid}
                </TableCell>

                <TableCell>
                  <IconButton onClick={() => {
                    props.onChatPress(n)
                  }}>
                    <ChatIcon />
                  </IconButton>
                </TableCell>
                
                <TableCell>
                  <div>
                    <IconButton onClick={() => {
                      props.onCallPress('video', n)
                    }}>
                      <Videocam />
                    </IconButton>
                    <IconButton onClick={() => {
                      props.onCallPress('audio', n)
                    }}>
                      <Phone />
                    </IconButton>
                  </div>
                </TableCell>

                <TableCell align="right"><span style={{color: n.onlineStatus.state === 'online' ? 'limegreen' : 'red'}}>{n.onlineStatus.state}</span></TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}

InstructorTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InstructorTable)