// import { ActivityIndicator, Button, Image, StyleSheet, TouchableOpacity, View } from 'react-native'

// import Colors from '../constants/Colors';
import React from 'react'
// import Svg, { Path, Text } from 'react-native-svg'
import { withStyles } from '@material-ui/core/styles'

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = (angleInDegrees-90) * Math.PI / 180.0
    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    }
  }

const makeArc = (x, y, radius, startAngle, endAngle, closePath) => {

    const start = polarToCartesian(x, y, radius, endAngle)
    const end = polarToCartesian(x, y, radius, startAngle)
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

    const d = [
        "M", start.x, start.y, 
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y]
  if (closePath) {
    d.push('L');
    d.push(x);
    d.push(y);
    d.push('Z');
  }
    return d.join(" ")
}

function StepProgressCircle ({ current, total, size = 100, style, borderWidth = 5 }) {
    const stepDegrees = 360 / total
    const degreeGap = 1.5

    let startAngle = 0

    const arcs = Array.from(Array(total)).map((d,i) => {
        const arc = <path key={`arc${i}`} d={makeArc(50, 50, 45, startAngle + degreeGap, startAngle + stepDegrees - degreeGap)} fill="transparent" stroke={"red"} strokeWidth={10} opacity={i >= current ? .3 : 1} />
        startAngle += stepDegrees
        return arc
    })

    return (<div style={{ height: size + borderWidth * 2, width: size + borderWidth * 2, borderRadius: (size + borderWidth * 2) / 2 }}><svg height={size} width={size} viewBox="0 0 100 100">
    {arcs}
    <text x={50} y={50} fontSize={32} fill="white" alignmentBaseline="middle" textAnchor="middle">{`${Math.round(current / total * 100)}%`}</text>
</svg></div>)
}

const styles = theme => ({
  container: {
      marginRight: 40
  }
})

export default withStyles(styles)(StepProgressCircle)