import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { storage } from '../firebase'
import { Howl } from 'howler'
// import { duration } from 'moment'
import CircularProgressPlayButton from './CircularProgressPlayButton'
import { Typography, Slider, Fab, Dialog, List, ListItem, ListItemAvatar, ListItemText, Box, DialogTitle } from '@material-ui/core'

import vidLake from '../assets/background-vids/37.mp4'
import vidLakeThumb from '../assets/background-vids/thumbs/37.mp4'
import vidLakeImg from '../assets/background-vids/thumbs/37.mp4.jpg'

import vidField from '../assets/background-vids/2018-02-05-16.34.40.mp4'
import vidFieldThumb from '../assets/background-vids/thumbs/2018-02-05-16.34.40.mp4'
import vidFieldImg from '../assets/background-vids/thumbs/2018-02-05-16.34.40.mp4.jpg'

import vidSunForest from '../assets/background-vids/Video-loop-Sun-Glaring-From-Trees.mp4'
import vidSunForestThumb from '../assets/background-vids/thumbs/Video-loop-Sun-Glaring-From-Trees.mp4'
import vidSunForestImg from '../assets/background-vids/thumbs/Video-loop-Sun-Glaring-From-Trees.mp4.jpg'

import { Panorama, Fullscreen, FullscreenExit } from '@material-ui/icons'
import { ContentFullscreenContext } from '../Contexts/content-fullscreen'

const vids = [
    {
        vid: vidLake,
        thumbVid: vidLakeThumb,
        img: vidLakeImg,
        name: 'Lake'
    },
    {
        vid: vidField,
        thumbVid: vidFieldThumb,
        img: vidFieldImg,
        name: 'Field'
    },
    {
        vid: vidSunForest,
        thumbVid: vidSunForestThumb,
        img: vidSunForestImg,
        name: 'Sun Forest'
    }
]


const styles = theme => ({
    container: {
        minHeight: 'calc(100% - 64px)',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    containerFullScreen: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    backgroundVideoButton: {
        position: 'fixed',
        right: 5,
        bottom: 5
    },
    fullscreenToggle: {
        position: 'fixed',
        left: 0,
        top: 0,
        transform: 'translate3d(75px, 70px, 0)',
        transition: 'transform 1s'
    },
    fullscreenToggleOpen: {
        position: 'fixed',
        left: 0,
        top: 0,
        transform: 'translate3d(5px, 5px, 0)',
        transition: 'transform 1s'
    },
    stepName: {
        // filter: 'invert(100%)',
        // mixBlendMode: 'difference'
        mixBlendMode: 'overlay',
        textShadow: '-1px -1px #fff, 1px 1px #000',
        fontSize: 50,
        color: theme.palette.primary.main
        // color: white;
    },
    stepNameActive: {
        fontWeight: 'bolder',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 10,
        filter: 'invert(1)'
    },
    clickable: {
        cursor: 'pointer'
    }
})

function CustomMeditationScreen(props) {
    const { classes, match } = props

    const contentFullscreenContext = useContext(ContentFullscreenContext)

    const [ meditation, setMeditation ] = useState(null)
    const [ activeStepIdx, setActiveStepIdx ] = useState(0)
    const [ currentStepLoaded, setCurrentStepLoaded ] = useState(false)
    const [ backgroundsLoaded, setBackgroundsLoaded ] = useState(false)
    const [ isPlaying, setIsPlaying ] = useState(false)
    const [ wasPlayingBeforeDrag, setWasPlayingBeforeDrag ] = useState(false)
    const [ progress, setProgress ] = useState(0)
    // const [ progressSecondsFormatted, setProgressSecondsFormatted ] = useState('00:00')
    // const [ audioPlayerItemDurationFormatted, setAudioPlayerItemDurationFormatted ] = useState(null)
    const [ playControl, setPlayControl ] = useState(null)
    const [ backgroundPlayControls, setBackgroundPlayControls ] = useState(null)
    const [ backgroundVolumes, setBackgroundVolumes ] = useState(null)
    const [ isBackgroundVideoPickerShowing, setIsBackgroundVideoPickerShowing ] = useState(false)
    const [ isMeditationFinsihed, setIsMeditationFinsihed ] = useState(false)

    const [ backVid, setBackVid ] = useState(null)

    const play = () => {
        playControl.play()
        backgroundPlayControls.forEach(pc => {
            pc.play()
        })
        setIsPlaying(true)
      }
      
      const pause = () => {
        playControl.pause()
        backgroundPlayControls.forEach(pc => {
            pc.pause()
        })
        setIsPlaying(false)
      }
  
      const togglePlay = () => {
          isPlaying ? pause() : play()
      }

      const loadStep = async (step, meditation, autoplay, activeStepIdx) => {
        let progressUpdater
        const url = await storage.refFromURL(step.item.gs).getDownloadURL()
        const playControl = new Howl({
            src: url,
            autoplay,
            onplay: () => {
            //   setIsPlaying(true)
              const dur = playControl.duration()
              progressUpdater = setInterval(() => {
                  const seconds = playControl.seek()
                //   setProgressSecondsFormatted(duration(seconds * 1000).format('hh:mm:ss', {
                //     stopTrim: 'm'
                // }))
                  setProgress(seconds / dur)
              }, 250)
            },
            onpause: () => {
            //   setIsPlaying(false)
              clearInterval(progressUpdater)
            },
            onend: () => {
              nextStep(meditation, activeStepIdx)
              clearInterval(progressUpdater)
            },
        //     onload: () => {
        //       setAudioPlayerItemDurationFormatted(duration(playControl.duration() * 1000).format('hh:mm:ss', {
        //         stopTrim: 'm'
        //     }))
        // }
        })
        setPlayControl(playControl)
        setCurrentStepLoaded(true)
    }

    const nextStep = (meditation, activeStepIdx) => {
        if (activeStepIdx === meditation.steps.length - 1) {
            return setIsMeditationFinsihed(true)
            //return alert('done')
        }
        setCurrentStepLoaded(false)
        const currentStep = meditation.steps[activeStepIdx + 1]
        setActiveStepIdx(activeStepIdx + 1)
        setProgress(0)
        loadStep(currentStep, meditation, true, activeStepIdx + 1)
      }

      const onSliderDrag = (evt, val) => {
          if (evt.type === 'mousedown') {
              console.log('isPLaying', isPlaying)
              if (isPlaying) {
                playControl.pause()
              }
              setWasPlayingBeforeDrag(isPlaying)
          }
          setProgress(val)
      }

      const onSliderDragEnd = (evt, val) => {
          setProgress(val)
          playControl.seek(playControl.duration() * val)
          if (wasPlayingBeforeDrag) {
            playControl.play()
          }
      }

      const showBackgroundVideoPicker = () => {
        setIsBackgroundVideoPickerShowing(true)
      }

      const toggleContentFullscreen = () => {
          contentFullscreenContext.setIsContentFullscreen(!contentFullscreenContext.isContentFullscreen)
      }

    useEffect(() => {
        // setTimeout(() => {
        //     console.log('ach', contentFullscreenContext)
        //     contentFullscreenContext.setIsContentFullscreen(true)
        //     setTimeout(() => {
        //         console.log('ach', contentFullscreenContext)
        //         contentFullscreenContext.setIsContentFullscreen(false)
        //     }, 2000)
        // }, 2000)
        const backgroundPlayControls = []
        const backgroundVolumes = []
        const loadBackgroundSounds = async backgrounds => {
            let backgroundSoundsLoaded = { num: 0}
            for (const { gs } of backgrounds) {
                backgroundVolumes.push(.5)
                const src = await storage.refFromURL(gs).getDownloadURL()
                backgroundPlayControls.push(new Howl({
                    src,
                    autoplay: false,
                    volume: .5,
                    onload: () => {
                        if (++backgroundSoundsLoaded.num === backgrounds.length) {
                            setBackgroundsLoaded(true)
                        }
                    }
                }))
            }
            setBackgroundPlayControls(backgroundPlayControls)
            setBackgroundVolumes(backgroundVolumes)
        }
        try {
            const med = JSON.parse(localStorage.getItem(match.params.id))
            setMeditation(med)

            const currentStep = med.steps[activeStepIdx]
            loadStep(currentStep, med, false, 0)
            // setActiveStep(currentStep)
            // Also load background sounds
            loadBackgroundSounds(med.backgrounds)

        } catch (e) {
            alert('Error loading custom meditation')
        }
    }, [ match.params.id ])

    return (<div className={classes.container}>
        { backVid && <video key={backVid.name} id="background-video" muted autoPlay loop style={{
            height: '100%',
            width: '100%',
            top: 0,
            padding: 0,
            position: 'fixed',
            left:  0,
            objectFit: 'cover',
            zIndex: -1
                }}>
            <source src={backVid.vid} type="video/mp4" />
        </video>
        }
        {
            meditation && meditation.steps.map((step, idx) => <Typography key={idx} className={classes.stepName} style={{ fontWeight: activeStepIdx === idx ? 'bold' : 'normal', mixBlendMode: activeStepIdx === idx ? 'normal' : 'overlay' }}>
                    {step.item.name}
                </Typography>)
        }
        <Slider value={progress} min={0} max={1} step={0.01} onChange={onSliderDrag} onChangeCommitted={onSliderDragEnd} />
        <CircularProgressPlayButton size={300} progress={progress} playing={isPlaying} ready={currentStepLoaded && backgroundsLoaded} playPausePress={togglePlay} style={backVid ? { mixBlendMode: 'overlay' } : {} } />
        {
            backgroundsLoaded && meditation.backgrounds.map((bs,i) => <Box key={i} alignSelf="stretch">
                <Typography>{bs.name}</Typography>
                <Slider min={0} max={1} step={0.01} value={backgroundVolumes[i]} onChange={(evt, value) => {
                    backgroundPlayControls[i].volume(value)
                    backgroundVolumes[i] = value
                    setBackgroundVolumes([...backgroundVolumes])
                }} />
                </Box>)
        }
        <Fab onClick={showBackgroundVideoPicker} color="primary" aria-label="add" className={classes.backgroundVideoButton}>
            <Panorama/>
        </Fab>
        <Fab onClick={toggleContentFullscreen} color="primary" aria-label="add" className={contentFullscreenContext.isContentFullscreen ? classes.fullscreenToggleOpen : classes.fullscreenToggle}>
            { contentFullscreenContext.isContentFullscreen ? <FullscreenExit/> : <Fullscreen /> }
        </Fab>
        <Dialog open={isMeditationFinsihed} scroll="paper">
           <Typography variant="h1">
            Done
           </Typography>
        </Dialog>
        <Dialog open={isBackgroundVideoPickerShowing} scroll="paper">
            <DialogTitle>Pick Background</DialogTitle>
            <List>
                {
                    vids.map(vid => {
                        const onClick = () => {
                            setBackVid(vid)
                            setIsBackgroundVideoPickerShowing(false)
                        }
                        return (<ListItem key={vid.name}>
                         <ListItemAvatar className={classes.clickable} onClick={onClick}>
                            <video src={vid.thumbVid} muted loop onMouseOver={evt => {
                                evt.target.play()
                            }} onMouseLeave={evt => {
                                evt.target.pause()
                            }} />
                         </ListItemAvatar>
                         <ListItemText primary={vid.name} className={classes.clickable} onClick={onClick} />
                        </ListItem>)})
                }
            </List>
        </Dialog>
        </div>)
}


CustomMeditationScreen.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
  };
  
  export default withRouter(withStyles(styles, { withTheme: true })(CustomMeditationScreen))