import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import ImageButton from './ImageButton'
import { withRouter } from "react-router-dom"
import LinearProgress from '@material-ui/core/LinearProgress'
import {
    PlaylistAdd, Lock
  } from '@material-ui/icons'
import { firestore } from '../firebase'
import { LanguageContext } from '../Contexts/language-context'
import { SubscriptionContext } from '../Contexts/subscription-context'
import { showSubscriptionAlert } from '../inter-component-comunication'

const getTexts = (item, lan) => {
    if (item.texts[lan]) {
        return item.texts[lan]
    } else {
        const defaultLan = Object.keys(item.texts)[0]
        return item.texts[defaultLan]
    }
}

function LibraryItems(props) {
    const { classes, match, dbPath, onItemClick, onAddToPlaylistClick } = props
    const [ items, setItems ] = useState(null)
    const language = useContext(LanguageContext)
    const subscription = useContext(SubscriptionContext)
    let unsubscribeFromitemsListener

    // const loadMeditations = async () => {
    //     const doc = await firestore.collection()
    // }

    useEffect(() => {
            let tmp = []
            unsubscribeFromitemsListener = firestore.collection(dbPath)
            .onSnapshot(snap => {
                snap.docChanges().forEach(change => {
                    tmp.push({...change.doc.data(), id: change.doc.id})
                })
                setItems(tmp)
            })
        // }
        return () => {
            unsubscribeFromitemsListener()
        }
    }, [match.path])
    return items ? (<div className={classes.container}>
        {
            items.map(item => {
                const texts = getTexts(item, language)
                return <ImageButton key={item.id}
                imgSrc={item.imgUrl}
                //TODO: Function to get name, even if language not exists
                title={texts.name}
                subtitle={texts.subtext}
                className={classes.item}
                onClick={() => {
                    if (!(item.free || subscription.active)) {
                        return showSubscriptionAlert.on()
                    }
                    onItemClick(item)
                }}
                topRight={!(item.free || subscription.active) ? <Lock/> : null}
                bottomRight={<div onClick={event => {
                    event.stopPropagation()
                    onAddToPlaylistClick(item)
                }}><PlaylistAdd /></div>} />
            })
        }
        </div>) : <LinearProgress variant="indeterminate" />
}

const styles = theme => ({
    container: {
        position: 'relative',
        height: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '1em'
    },
    item: {
        // margin: 5
    }
  })

  LibraryItems.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(LibraryItems))