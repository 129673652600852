import { Button, TextField } from '@material-ui/core'
import React, { Component } from 'react'
import { auth, firebase, firestore } from '../firebase'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from "react-router-dom"
import Link from '@material-ui/core/Link'

const ChatBubble = ({ message, isMessageFromMe, isPreviousMessageFromSameSender, isNextMessageFromSameSender }) => <div
style={{
    whiteSpace: 'pre',
    padding: '10px',
    borderRadius: '20px',
    margin: '1px',
    // fontSize: 18,
    backgroundColor: isMessageFromMe ? '#28003D' : '#FFDD59',
    color: isMessageFromMe ? '#FFDD59' : '#28003D',
    alignSelf: isMessageFromMe ? 'flex-start' : 'flex-end',
    borderTopRightRadius: isMessageFromMe ? '20px' : isPreviousMessageFromSameSender ? 0 : '20px',
    borderTopLeftRadius: isMessageFromMe ? isPreviousMessageFromSameSender ? 0 : '20px' : '20px',
    borderBottomRightRadius: isMessageFromMe ? '20px' : isNextMessageFromSameSender ? 0 : '20px',
    borderBottomLeftRadius: isMessageFromMe ? isNextMessageFromSameSender ? 0 : '20px' : '20px'
}}>
    {message}
</div>

class Chat extends Component {

    state = {
        otherUserTyping: false,
        messages: [],
        newMessageText: '',
        selectedChat: null
    }
    amITyping = false

    sendMessage = () => {
        this.setAmITyping(false)
        const { uid } = auth.currentUser
        firestore.collection(`test/chat/users/${this.chatId}/messages`)
        .add({
            // senderUid: uid,
            text: this.state.newMessageText,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            user: {
                _id: uid
            }
        })
        this.setState({ newMessageText: '' })
    }

    setAmITyping = amITyping => {
        this.amITyping = amITyping
        const { uid } = auth.currentUser
        firebase.firestore().doc(`test/chat/users/${this.chatId}`)
        .update({
            isTyping: {
                [uid]: amITyping
            }
        })
    }

    onNewMessageChange = evt => {
        const newMessageText = evt.target.value
        if (newMessageText !== '' && !this.amITyping) {
            this.setAmITyping(true)
        } else if (newMessageText === '' && this.amITyping) {
            this.setAmITyping(false)
        }
        this.setState({ newMessageText })
    }

    onNewMessageKeyPress = evt => {
        if (evt.charCode === 13 && !evt.shiftKey) {
            evt.preventDefault()
            this.sendMessage()
        }
    }

    scrollChatDown = () => {
        // Maybe only scroll if already at bottom!

    }

    subscribeToChat = chatId => firestore.collection(`test/chat/users/${chatId}/messages`)
    .orderBy('createdAt')
    .onSnapshot(snap => {
        snap.docChanges().forEach(change => {
            if (change.type === 'added') {
                const data = change.doc.data()
                if (!data._id) {
                    data._id = change.doc.id
                }
                this.setState({
                    messages: [...this.state.messages, data]
                })
                // TODO:  animate scroll
                this.chatDiv.scrollTop = this.chatDiv.scrollHeight
            }
        })
    })

    onChatClick = selectedChat => {
        if (this.unsubscribeFromChat) {
            this.unsubscribeFromChat()
        }
        this.chatId = selectedChat.members.sort().join('-')
        this.unsubscribeFromChat = this.subscribeToChat(this.chatId)
        this.setState({ selectedChat })
    }

    componentDidMount = () => {
        const { match } = this.props
        const { chatUid } = match.params
        this.chatDiv = document.getElementById('chat-messages')
        if (chatUid) {
            this.onChatClick({
                members: chatUid.split('-')
            })
        }
    }

    render = () => {
        const { classes } = this.props
        const {
            messages,
            // otherUserTyping,
            newMessageText
        } = this.state
        const { uid } = auth.currentUser
        let previousMessageSenderUid = ''
        return (<div style={{height:'calc(100% - 64px)'}}><div style={{display:'flex',flexDirection:'row',height:'100%'}}>
            <div style={{flex:1, display: 'flex', height: '100%', flexDirection: 'column'}}>
            <Link component={RouterLink} className={classes.goBack} to="/instructor-session">&lt; back</Link>
            <div id="chat-messages" style={{display: 'flex', flexDirection: 'column', overflowY: 'scroll'}}>
            {
                messages.map((msg, idx) => {
                    const isMessageFromMe = msg.user._id === uid
                    const isPreviousMessageFromSameSender = previousMessageSenderUid === msg.user._id
                    const isNextMessageFromSameSender = idx === messages.length - 1 ? false : messages[idx + 1].user._id === msg.user._id
                    previousMessageSenderUid= msg.user._id
                    return <ChatBubble key={msg._id} message={msg.text} isMessageFromMe={isMessageFromMe} isPreviousMessageFromSameSender={isPreviousMessageFromSameSender} isNextMessageFromSameSender={isNextMessageFromSameSender}  />
                })
            }
            </div>
          <div style={{flexGrow: 1}} />
          <div style={{display: 'flex'}}>
          <TextField value={newMessageText} onKeyPress={this.onNewMessageKeyPress} onChange={this.onNewMessageChange} fullWidth multiline placeholder="enter message..." />
          <Button onClick={this.sendMessage}>send</Button>
          </div>
            </div>
            </div></div>)
    }
}

const styles = {
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    goBack: {
        alignSelf: 'flex-start'
    }
  }

Chat.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
export default withStyles(styles)(Chat)