import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
// import { CircularProgressbar } from 'react-circular-progressbar'
import { CircularProgress } from '@material-ui/core'
import { PlayArrow, PauseSharp } from '@material-ui/icons'

function CircularProgressPlayButton(props) {
    const { classes, theme, playPausePress, progress, ready = true, playing = false, size = 100, style= {} } = props
    const playPauseAnimationContainer = useRef(null)
    //const [state, setState] = useState({})
    // const [playing, setPlaying] = useState(false)
    //const variant = ready ? 'determinate' : 'indeterminate'
    useEffect(() => {
        // console.log('elem is', playPauseAnimationContainer.current)
        // loadAnimation({
        //     element: playPauseAnimationContainer.current,
        //     loop: true,
        //     autoplay: true,
        //     // animationData: playPauseAnimationData,
        //     animationData: animationData,
        //     renderer: 'svg'
        // })
    }, [])
    const iconStyle = { fontSize: size * .75, color: theme.palette.primary.main }
    return (
        <div className={classes.container} style={{ height: size, width: size, ...style }}>
            <div className={classes.circleContainer}>
                { ready ? <CircularProgress size={size} value={progress * 100} variant="static" /> : <CircularProgress size={size} /> }
            </div>
            <div className={classes.childrenContainer}>
                <div onClick={playPausePress} className={classes.animation} ref={playPauseAnimationContainer}>
                { ready ? playing ? <PauseSharp style={iconStyle}/> : <PlayArrow style={iconStyle}/> : 'loading...' }
                </div>
            </div>
        </div>
        )
}

const styles = theme => ({
    container: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // margin: 'auto'
    },
    circleContainer: {
        position: 'absolute'
    },
    childrenContainer: {
        position: "absolute",
        height: "100%",
        width: "100%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    animation: {
        // height: 200,
        // width: 200
    }
  })

  CircularProgressPlayButton.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(CircularProgressPlayButton)