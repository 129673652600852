import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link as RouterLink } from "react-router-dom"
import Link from '@material-ui/core/Link'
import ImageButton from './ImageButton'
import { withTranslation } from 'react-i18next'

import meditateImage from '../assets/img/meditieren-pic.jpg'
import createMeditationImage from '../assets/img/meditation-erstellen-pic.jpg'

function Home(props) {
    const { classes, t } = props

    return ( <div className={classes.container}>
        <Link component={RouterLink} to="/library/meditations">
            <ImageButton title={t('Meditate')}
            subtitle={t('Browse through meditations')}
            imgSrc={meditateImage}
            className={classes.imgButton} />
        </Link>
        <Link component={RouterLink} to="/meditation-creator">
            <ImageButton title={t('Create meditation')}
            subtitle={t('Create meditation text')}
            imgSrc={createMeditationImage}
            className={classes.imgButton} />
        </Link>

        </div>)
}

const styles = theme => ({
    container: {
        position: 'relative',
        height: '100%',
        width: '100%'
    },
    imgButton: {
        margin: 5
    }
  })

  Home.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTranslation()(withStyles(styles)(Home))