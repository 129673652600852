import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { UserDataJourneysContext } from '../Contexts/user-data-journeys'
import StepProgressCircle from './StepProgressCircle'

const styles = {
  card: {
    maxWidth: 1134,
    margin: 'auto'
  },
  media: {
    height: 502,
  },
  progressIndicator: {
    position: 'absolute',
    top: 5,
    right: 5
  }
};

function JourneyCard(props) {
  const { classes, journey } = props
  const userDataJourneysContext = useContext(UserDataJourneysContext)  
  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={journey.picURL}
          title={journey.name}
        ><Box className={classes.progressIndicator}>{ userDataJourneysContext[journey.id] && <StepProgressCircle current={userDataJourneysContext[journey.id].currentStep} total={journey.numberOfSessions} /> }</Box></CardMedia>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {journey.name}
          </Typography>
          <Typography component="p">
            {journey.infoText}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions>
    </Card>
  )
}

JourneyCard.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(JourneyCard)