export default {
    // BackgroundImagesScreen
    'Once you download images to your device they will show up here': 'Once you download images to your device they will show up here',
    'Download new images': 'Download new images',

    // Create MYditationScreen
    'Select how you want your meditation to begin:': 'Select how you want your meditation to begin:',
    'Select your topic(s) and press next:': 'Select your topic(s) and press next:',
    'How long would you like to pause after the topic(s) completed? (Background sounds will keep playing)': 'How long would you like to pause after the topic(s) completed? (Background sounds will keep playing)',
    'Select how you would like to be guided out of the meditation:': 'Select how you would like to be guided out of the meditation:',
    'How long would you the background sounds to continue playing?': 'How long would you the background sounds to continue playing?',
    'Select as many background sounds as you would like:': 'Select as many background sounds as you would like:',

    // DownloadManagerScreen
    'MYDitation Creator Items': 'MYDitation Creator Items',
    'Delete MYDitation Creator Item?': 'Delete MYDitation Creator Item?',
    'Are you sure you want to delete {{name}}': 'Are you sure you want to delete {{name}}',
    'Delete Journey?': 'Delete Journey?',
    'Delete Course?': 'Delete Course?',

    // EditGoalsScreen
    "What's your daily meditation goal?": "What's your daily meditation goal?",
    'How many days per week would you like to meditate?': 'How many days per week would you like to meditate?',
    'Weekly goal': 'Weekly goal',
    'Monthly goal': 'Monthly goal',

    // FaqScreen
    // TODO

    // FavouritesScreen
    "You haven't added any favourites yet": "You haven't added any favourites yet",

    // FeedbackScreen
    'General': 'General',
    'Meditation Suggestion': 'Meditation Suggestion',
    'Topic Suggestion': 'Topic Suggestion',
    'App suggestion': 'App suggestion',
    'Bug report': 'Bug report',
    'Please enter your suggestion first': 'Please enter your suggestion first',
    'We will listen to your feedback': 'We will listen to your feedback...',
    'At MYDitation we work hard on making the best possible products for our customers and always have an open ear for feedback and suggestions': 'At MYDitation we work hard on making the best possible products for our customers and always have an open ear for feedback and suggestions.',
    'Please select the category:': 'Please select the category:',
    'Type your feedback or suggestion': 'Type your feedback or suggestion...',

    // JourneyScreen
    'Would you like to do this session again?': 'Would you like to do this session again?',

    // LibraryMyditationsScreen
    'Record meditations': 'Record meditations',

    // LibraryScreen
    'MYditations': 'MYditations',
    'Personal content, record and upload new content': 'Personal content, record and upload new content',
    'Meditations': 'Meditations',
    'Collection of complete meditations': 'Collection of complete meditations',
    'Music': 'Music',
    'Collection of soothing and relaxing music': 'Collection of soothing and relaxing music',
    'Healing Sounds': 'Healing Sounds',
    'Binaural beats & Solfeggio tones': 'Binaural beats & Solfeggio tones',
    'Visuals': 'Visuals',
    'Collection of soothing and relaxing backgrounds': 'Collection of soothing and relaxing backgrounds',

    // MyDiateScreen
    'Create your Meditation': 'Create your Meditation',
    'Previous Meditations': 'Previous Meditations',
    'Start': 'Start',
    'START': 'START',
    'nothing to show yet': 'nothing to show yet',

    // MYditationScreen
    'Meditation finished': 'Meditation finished',
    'Would you like to save it?': 'Would you like to save it?',

    // PrivacyPolicyScreen
    // TODO

    // ProfileScreen
    'Take photo with camera': 'Take photo with camera',
    'Pick from library': 'Pick from library',
    'Set Goals': 'Set Goals',
    'Register free account': 'Register free account',
    'Register a free account to unlock all the features of the app!': 'Register a free account to unlock all the features of the app!',
    'Set reminders': 'Set reminders',
    'Favourites': 'Favourites',
    'Downloads': 'Downloads',
    'Restore Purchases': 'Restore Purchases',
    'restoring purchases': 'restoring purchases...',
    'itunes Error': 'itunes Error',
    'Could not connect to itunes store.': 'Could not connect to itunes store.',
    'No Purchases': 'No Purchases',
    "We didn't find any purchases to restore.": "We didn't find any purchases to restore.",
    'FAQ': 'FAQ',
    'Feedback': 'Feedback',
    'Rate app': 'Rate app',
    'YouTube': 'YouTube',
    'Terms & Conditions': 'Terms & Conditions',
    'Privacy Policy': 'Privacy Policy',
    'Sign out': 'Sign out',
    'Yes, sign out': 'Yes, sign out',
    "Account": "Account",


    // RecordMeditationScreen
    "Name can't be empty": "Name can't be empty",
    'Name not unique': 'Name not unique',
    'A recording with the name {{newRecordingName}} already exists for {{newRecordingCategory}}': 'A recording with the name {{newRecordingName}} already exists for {{newRecordingCategory}}',
    'Use': 'Use',
    'button to add a new recording': 'button to add a new recording.',

    // SetRemindersScreen
    'MYDitation Reminder': 'MYDitation Reminder',
    "It's time to meditate!": "It's time to meditate!",
    'Daily?': 'Daily?',
    'Set all days': 'Set all days',
    'Set daily reminder time': 'Set daily reminder time',
    'Set reminder time for': 'Set reminder time for',
    'all days': 'all days',
    'Cancel all reminders': 'Cancel all reminders',

    // TaCScreen
    // TODO
    
    // Error messages
    'Error loading data\nTry again later': 'Error loading data\nTry again later',
    'Error': 'Error',

    // Other alerts
    'Not yet unlocked': 'Not yet unlocked',
    'You need to complete the previous sessions first before you can access {{name}}': 'You need to complete the previous sessions first before you can access {{name}}',

    // Shared
    'cancel': 'cancel',
    'yes': 'yes',
    'Success': 'Success',
    'Successfully deleted {{name}}': 'Successfully deleted {{name}}',
    'Journeys': 'Journeys',
    'Courses': 'Courses',
    'Thank You': 'Thank You',
    'send': 'send',
    'Well done!': 'Well done!',
    'You have finished {{name}}': 'You have finished {{name}}',
    'OK': 'OK',
    'Error opening link': 'Error opening link',
    'Day': 'Day',
    'Week': 'Week',
    'Month': 'Month',
    'Are you sure?': 'Are you sure?',
    'Save': 'Save',
    'Reset': 'Reset',

    // V2
    //Creator
    'Introduction': 'Introduction',
    'Topics': 'Topics',
    'Ending': 'Ending',
    'Pause': 'Pause',
    'Backgrounds': 'Backgrounds',
    'skip': 'skip',
    'next': 'next',
    'back': 'back',
    'minutes': 'minutes',
    //Tabs und headers
    'MYDitate':'MYDitate',
    'Library': 'Library',
    'Profile': 'Profile',
    'START SESSION': 'START SESSION',
    'Saved recordings': 'Saved recordings',
    'Set category': 'Set category',
    'Select the category for {{name}}': 'Select the category for {{name}}',
    'My Images': 'My Images',
    'Introduction [audio only]': 'Introduction [audio only]',
    'Start 30-day free trial': 'Start 30-day free trial',

    //V3
    //CustomMeditationPreview
    'then pause for {{pause}} minute': '{{pause}} minute pause',
    'then pause for {{pause}} minutes': '{{pause}} minutes pause',
    'Background Sounds': 'Background Sounds',
    'Topic': 'Topic',

    //V4 Neuer home screen etc..
    'Browse meditations': 'Browse meditations',
    'Create custom meditation': 'Create custom meditation',
    'Languages': 'Languages',
    'english': 'English',
    'german': 'German',
    'no': 'no',
    'MYditate': 'MYditate',
    'Please restart the app for changes to take effect': 'Please restart the app for changes to take effect.',
    'language': 'language',
    'app language text': 'Select the main language for the app. (App restart required for changes to take effect)',
    'Edit goals': 'Edit goals',
    'Set goals': 'Set goals',
    'Meditate': 'Meditate',
    'Browse through meditations': 'Browse our library of guided meditations',
    'Create meditation': 'Create meditation',
    'Create meditation text': 'Select from our different elements to create your unique meditation',
    'Level': 'Level',
    'Lessons': 'Lessons',
    'Record Meditation': 'Record Meditation',

    // Subscribe modal:
    'Unlock': 'Unlock everything',
    'New & updated content added every week': 'New & updated content added regularly',
    'Entire library containing hours of': 'Entire library containing hours of',
    'guided meditations': 'guided meditations',
    'soothing music': 'soothing music',
    'healing frequencies': 'healing frequencies',
    'nature sounds': 'nature sounds',
    'Video Courses and animated Meditation Journeys': 'Video Courses & animated Meditation Journeys',
    'Full access to all MYditation creator content': 'Full access to all MYditation creator content',
    'Start your': 'Start your',
    '30 DAY FREE TRIAL': '30 DAY FREE TRIAL',
    'and afterwards continue with:': 'and afterwards continue with:',
    'Monthly': 'Monthly',
    'billed monthly': 'billed monthly',
    'BEST VALUE': 'BEST VALUE',
    'Yearly': 'Yearly',
    'billed yearly': 'billed yearly',
    '3 months': '3 months',
    'billed quarterly': 'billed quarterly',
    'long legal text': 'Payments will be charged to your iTunes account at confirmation of purchase. Subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current period. Account will be charged for renewal within 24-hours prior to the end of the current period, and identify the cost of the renewal. Subscriptions may be managed by the user and auto-renewal may be turned off by going to the users’s Account Settings after purchase. Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription to that publication, where applicable.',
    
    // V5
    'introduction': 'introduction',
    'topic': 'topic',
    'ending': 'ending',
    'YouTube-link': 'https://www.youtube.com/channel/UC4i_5BVB0UImMy--EiHCcAw',
    'de': 'German',
    'en': 'English',
    'App language set to': 'App language set to',
    'Preferences': 'Preferences',
    'Customise the app to your preferences': 'Customise the app to your preferences.',
    'You can change these later on the profile screen': 'You can change these later on the profile screen.',
    'Select app language': 'Select app language',
    'Language changes will be applied after you press the done button': 'Language changes will be applied after you press the done button.',
    'Select your interests': 'Select your interests',
    'Weight Loss': 'Weight Loss',
    'Sleep': 'Sleep',
    'Motivation': 'Motivation',
    'Traditional Methods': 'Traditional Methods',
    'Relaxation': 'Relaxation',
    'Health': 'Health',
    'Anxiety & Stress Reduction': 'Anxiety & Stress Reduction',
    'Pregnancy': 'Pregnancy',
    'Mindfulness': 'Mindfulness',

    // Sign-up Modal
    'Sign-up or login': 'Sign-up or login',
    'To get the most out of MYditation, we recommend you sign-up for a free account or login if you already have an account': 'To get the most out of MYditation, we recommend you sign-up for a free account or login if you already have an account.',
    'later': 'later',
    'dont show again': "don't show again",
    'By using this app you agree to our': 'By using this app you agree to our',
    'terms': 'terms',
    'and our': 'and our',
    'privacy policy': 'privacy policy',
    'Already have an account': 'Already have an account?',
    'login with facebook': 'login with facebook',
    'REGISTER': 'REGISTER',
    'password': 'password',
    'email': 'email',

    // V6
    'load previous meditation': 'load previous meditation',

    // Intro (onb-oarding)
    'Welcome to MYDitation': 'Welcome to MYditation',
    'your unique meditation experience': 'your unique meditation experience',
    'Before you get started, you can set your preferences to adjust the app to your personal taste - all options can be changed again later on in the profile menu': 'Before you get started, you can set your preferences to adjust the app to your personal taste - all options can be changed again later on in the profile menu.',
    'Language': 'Language',
    'Interests': 'Interests',
    'Please select your preferences so that we can adjust the content to your personal needs': 'Please select your preferences so that we can adjust the content to your personal need:',
    'Goals': 'Goals',
    'Goals-subtext': 'Practice creates masters!',
    'Goals-paragraph': 'To reach your goals and continue to benefit from meditating, you can set your very own meditation-goals, as well as reminders to stay on track.',
    'Frequencies': 'Frequencies',
    'Frequencies-subtext': 'Solfeggios and Binaural Beats',
    'frequencies-subtext-pre-learn-more': 'In this app you have the opportunity to enhance meditations with background elements, such as healing frequencies',
    'frequencies-subtext-learn-more': 'learn more',
    'frequencies-subtext-post-learn-more': 'Music and Ambience. You can select them before or during the meditation. Many meditations come with background element presets. Would oyu like to load these presets by default?',
    'Meditation-Creator': 'Meditation-Creator',
    'Meditation-Creator-subtext': 'Create your own meditations',
    'Meditation-Creator-subtext-p1': 'The Meditation-Creator gives you the ability to assemble your very own session. You can choose from our meditations [introductions, topics, etc.] and mix and match them to your liking - or you can record your own meditations or elements and use them inside of the creator.',
    'Meditation-Creator-subtext-p2': 'You can always choose your own background elements, just as in the library, so you can continue to meditate to your personal preferences and in your ideal atmosphere for relaxation!',
    'finish': 'finish',


    // NEU
    'Lifetime': 'Lifetime',
    'billed once': 'billed once',
    'Only {{price}} per month': 'Only {{price}} per month',
    'Voice only': 'Voice only',
    'Voice with background audio presets': 'Voice with background audio presets',

    //NEUER
    'App content languages': 'App content languages:',
    'Select the languages you would like to show app content in': 'Select the languages you would like to show app content in.',
    'Are you sure you want to change the app language': 'Are you sure you want to change the app language?',
    'If you select yes, the app will automatically restart in the language you have chosen': 'If you select yes, the app will automatically restart in the language you have chosen.',
    'Reminders': 'Reminders',
    'Weekly reminders set successfully!': 'Weekly reminders set successfully!',
    'Weekly reminder set successfully!': 'Weekly reminder set successfully!',
    'Daily reminder set successfully!': 'Daily reminder set successfully!',
    'No downloads yet': 'No downloads yet',
    'UNLOCK EVERYTHING': 'UNLOCK EVERYTHING',
    'You need to be subscribed to access this content': 'You need to be subscribed to access this content',
    'learn more': 'learn more',
    'Email invalid': 'Email invalid',
    'Please enter a valid email address to request a password reset': 'Please enter a valid email address to request a password reset',
    'Password reset success': 'Password reset success',
    'Please check your email for instructions on how to reset you password': 'Please check your email for instructions on how to reset you password',
    'Error resetting password': 'Error resetting password',
    'Sign in': 'Sign in',
    'Forgot password': 'Forgot password?',
    'Please sign in with the account you made the purchases with to restore your purchases': 'Please sign in with the account you made the purchases with to restore your purchases.',
    'close': 'close',
    'preview': 'preview',
    'Settings': 'Settings',
    'Use audio presets': 'Use audio presets',
    'minute': 'minute',
    'edit selections': 'edit selections',
    'Unnamed': 'Unnamed',
    'Edit Meditation': 'Edit Meditation',
    'name': 'name',
    'Download in progress': 'Download in progress...',
    'Please wait until the download has finished': 'Please wait until the download has finished.',
    'creator-item': 'Creator items',
    'library-item': 'Library items',
    'At the end of a meditation': 'At the end of a meditation...',
    'the music stops immediately': '...the music stops immediately',
    'Sounds a gong': 'Sounds a gong',
    'sounds a gong and ': 'sounds a gong and ',
    'the music stops playing immediately': 'the music stops playing immediately',
    'the music stops playing immediately2': 'the music stops playing immediately.',
    'stops playing immediately': 'stops playing immediately',
    'stops immediately': 'stops immediately',
    'the music continues to play for {{minutes}} minutes': 'the music continues to play for {{minutes}} minutes.',
    'the music continues to play for {{minutes}} minutes2': 'the music continues to play for {{minutes}} minutes.',
    'The music continues to play for': 'The music continues to play for',
    // 'sounds a gong and the music stops playing immediately': '...sounds a gong and the music stops playing immediately',
    // 'sounds a gong and the music keeps playing for {{minutes}} minutes': '...sounds a gong and the music keeps playing for {{minutes}} minutes'
    'Press here to learn more': 'Press here to learn more',
    'Fix broken downloads': 'DOWNLOAD CHECK',
    'If playback of downloaded files is not working': 'If playback of downloaded files is not working, you can check files with this function. Broken files will be removed and need to be downloaded again for offline use. Thanks for understanding. Namasté',

    //NEW:
    'Meditation image': 'Meditation image'
}