import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { de, en } from './translations'

// TODO: Import de.json and es.json from RN app
// const resources = {
//   en: {
//     translation: {
//       'Journeys': 'Journeys',
//       'Courses': 'Courses',
//     }
//   },
//   de: {
//     translation: {
//       "Journeys": "Reisen",
//       "Courses": "Kurse",
//       }
//   }
// };

const resources = {
  en: {
    translation: en
  },
  de: {
    translation: de
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "de",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;