import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Typography, Chip, Box, Switch, Dialog, DialogTitle, Input, Button } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { auth, firestore } from '../firebase'
import { UserDataContext } from '../Contexts/user-data'
import { UserDataSettingsContext } from '../Contexts/user-data-settings'
import { SubscriptionContext } from '../Contexts/subscription-context'
import { 
    Done
  } from '@material-ui/icons'

const FAVOURITE_TOPICS_LIST = [
    { key: 'weight-loss', name: 'Weight Loss' },
    { key: 'sleep', name: 'Sleep' },
    { key: 'motivation', name: 'Motivation' },
    { key: 'success', name: 'Success' },
    { key: 'traditional-methods', name: 'Traditional Methods' },
    { key: 'relaxation', name: 'Relaxation' },
    { key: 'health', name: 'Health' },
    { key: 'anxiety-and-stress-reduction', name: 'Anxiety & Stress Reduction' },
    { key: 'pregnancy', name: 'Pregnancy' },
    { key: 'mindfulness', name: 'Mindfulness' }]

function Profile(props) {
    const { classes, t } = props

    const userData = useContext(UserDataContext)
    const userDataSettings = useContext(UserDataSettingsContext)
    const subscription = useContext(SubscriptionContext)

    const [isEndOfMeditationsSettingsDialogShowing, setIsEndOfMeditationsSettingsDialogShowing] = useState(false)

    const { currentUser } = auth

    const toggleInterest = key => {
        userDataSettings.interests.topics[key] = !userDataSettings.interests.topics[key]
        firestore.doc(`user-data/${currentUser.uid}/settings/interests`)
        .set(userDataSettings.interests, { merge: true })
    }

    const toggleUseAudioPresets = () => {
        userDataSettings.audioPresets.use = !userDataSettings.audioPresets.use
        firestore.doc(`user-data/${currentUser.uid}/settings/audioPresets`)
        .set(userDataSettings.audioPresets, { merge: true })
    }

    const showEndOfMeditationsSettingsDialog = () => {
        setIsEndOfMeditationsSettingsDialogShowing(true)
    }
    
    const hideEndOfMeditationsSettingsDialog = () => {
        setIsEndOfMeditationsSettingsDialogShowing(false)
    }

    const toggleMeditationSettinsPlayGongAtEnd = () => {
        userDataSettings.meditation.playGongAtEnd = !userDataSettings.meditation.playGongAtEnd
        firestore.doc(`user-data/${currentUser.uid}/settings/meditation`)
        .set(userDataSettings.meditation, { merge: true })
    }
    
    const setMeditationSettinsContinuePlayingMusicForMinutes = evt => {
        const minutes = evt.target.value
        userDataSettings.meditation.continuePlayingMusicForMinutes = minutes
        firestore.doc(`user-data/${currentUser.uid}/settings/meditation`)
        .set(userDataSettings.meditation, { merge: true })
    }

    const signOut = () => {
        auth.signOut()
    }

    return ( <div className={classes.container}>
        <Typography variant="h2">
            {t('Profile')}
        </Typography>
        {
            currentUser.isAnonymous ? <Button>
             Sign up or login
            </Button> :
            <div>
        <Typography variant="h3">
            {t('Account')}
        </Typography>
        <div>
        {
            currentUser.providerData.map(pd => {
                switch (pd.providerId) {
                    case 'password':
                        return <div key={pd.email}>
                        Email: {pd.email}
                        </div>
                    default:
                        return null
                }
            })
        }
        </div>
        <div>
        {
            userData.name && <div>Name: {userData.name}</div>
        }
        </div>
        <Button onClick={signOut}>
            Sign Out
        </Button>
        </div>
        }
        {/* <Typography>
            {JSON.stringify(currentUser)}
            ----
            {JSON.stringify(userData)}
            ====
            {JSON.stringify(userDataSettings)}
            +++++
            {JSON.stringify(subscription)}
        </Typography> */}
        <Box flexDirection="row">
            <Switch
                checked={userDataSettings.audioPresets.use}
                onChange={toggleUseAudioPresets}
                inputProps={{ 'aria-label': 'use audio presets' }}
            />
            <Typography variant="caption">
                {t('Use audio presets')}
            </Typography>
        </Box>
        <Box flexDirection="column" onClick={showEndOfMeditationsSettingsDialog}>
            <Typography>
                {t('At the end of a meditation')}
            </Typography>
        </Box>
        {
            FAVOURITE_TOPICS_LIST.map(fav => {
                const isSelected = userDataSettings.interests.topics[fav.key]
                return <Chip key={fav.key} color="primary" variant={isSelected ? 'default' : 'outlined'} label={t(fav.name)} deleteIcon={isSelected ? null : <Done />} onDelete={() => {
                    toggleInterest(fav.key)
                }} />
            })
        }
        <Dialog onClose={hideEndOfMeditationsSettingsDialog} open={isEndOfMeditationsSettingsDialogShowing}>
            <DialogTitle>{t('At the end of a meditation')}</DialogTitle>
            <Box flexDirection="row">
                <Typography>
                    {t('Sounds a gong')}
                </Typography>
                <Switch
                    checked={userDataSettings.meditation.playGongAtEnd}
                    onChange={toggleMeditationSettinsPlayGongAtEnd}
                />
            </Box>
            <Typography>
                {t('the music continues to play for {{minutes}} minutes2', { minutes: userDataSettings.meditation.continuePlayingMusicForMinutes })}
            </Typography>
            <Input type="number" value={userDataSettings.meditation.continuePlayingMusicForMinutes} onChange={setMeditationSettinsContinuePlayingMusicForMinutes} />
        </Dialog>
        </div>)
}

const styles = theme => ({
    container: {
        position: 'relative',
        minHeight: 'calc(100% - 64px)',
        width: '100%'
    }
  })

  Profile.propTypes = {
  classes: PropTypes.object.isRequired,
}

// export default withStyles(styles)(Profile)
export default withTranslation()(withStyles(styles)(Profile))