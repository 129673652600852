import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseRounded from '@material-ui/icons/PauseRounded';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LoopIcon from '@material-ui/icons/LoopRounded';
// import ArrowUpward from '@material-ui/icons/ArrowUpward';
// import ArrowDownward from '@material-ui/icons/ArrowDownward';
import PlaylistPlay from '@material-ui/icons/PlaylistPlay';
import { CircularProgress, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, Slider, Box } from '@material-ui/core';
import { storage } from '../firebase';
import { Howl } from 'howler'
import { Remove, Clear, AddCircle, UnfoldLess, UnfoldMore } from '@material-ui/icons'
import { LanguageContext } from '../Contexts/language-context'
import { duration } from 'moment'
import SolfeggioSlider from './SolfeggioSlider'
import uuid from 'uuid/v4'
import classNames from 'classnames'

const PLAYER_HEIGHT_FREE = 273
const PLAYER_HEIGHT_TOP_BAR = 64

const frequencyControls = {
  right: 0,
  height: 273,
  background: 'rgba(255,255,255,.2)',
  backdropFilter: 'blur(50px)',
  zIndex: 1,
  width: 200,
  position: 'absolute',
  transform: 'translate3d(0, 0, 0)',
  transition: 'transform .5s, height .5s'
}

const frequencyControlsToggle = {
  transition: 'transform .5s'
}

const styles = theme => ({
  card: {
    display: 'flex',
    zIndex: 2,
    transition: 'height .5s'
  },
  details: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 150,
    backgroundSize: [100, 100]
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playAndProgressButton: {
      position: 'relative'
  },
  playAndProgressButtonProgress: {
      position: 'absolute',
      width: 40,
      height: 40,
      top: -8,
      left: -8
  },
  playAndProgressButtonPlay: {
    position: 'absolute',
    height: 38,
    width: 38
  },
  container: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 500,
      overflow: 'hidden'
  },
  cardFree: {
    height: PLAYER_HEIGHT_FREE
  },
  cardTopBar: {
    height: PLAYER_HEIGHT_TOP_BAR
  },
  playlist: {
      zIndex: 1
  },
  frequencyControls,
  frequencyControlsOpen: {
      ...frequencyControls,
      transform: 'translate3d(200px, 0, 0)'
  },
  frequencyControlsToggleOpen: {
    ...frequencyControlsToggle,
    transform: 'rotate3d(0, 0, 1, 90deg)'
  },
  frequencyControlsToggleClosed: {
    ...frequencyControlsToggle,
    transform: 'rotate3d(0, 0, 1, -90deg)'
  },
  frequencyControlsGrid: {
    height: '100%',
    padding: 10
  },
  compactModeButton: {
    position: 'absolute',
    top: 0,
    left: 0
  }
})

function CompactAudioPlayer(props) {
  const {
    classes,
    theme,
    item,
    playlist,
    // setItem,
    setPlaylist,
    playListIndex,
    setPlayListIndex,
    setCompactPlayerDragDisabled,
    setPlayListItem,
    playNextPlaylistItem,
    mode,
    toggleCompactPlayerMode
  } = props

  const language = useContext(LanguageContext)

  const [ playControl, setPlayControl ] = useState(null)
  const [ isPlaying, setIsPlaying ] = useState(false)
  const [ progress, setProgress ] = useState(0)
  const [ progressSecondsFormatted, setProgressSecondsFormatted ] = useState('00:00')
  const [ isPlaylistOpen, setIsPlaylistOpen ] = useState(false)
  const [ audioPlayerItemDurationFormatted, setAudioPlayerItemDurationFormatted ] = useState('00:00')
  // const [ playListIndex, setPlayListIndex ] = useState(0)
  const [isfrequeinciesControlsOpen, setIsfrequeinciesControlsOpen] = useState(false)
  const [frequencySliders, setFrequencySliders] = useState([])

  const isFreeMode = mode === 'free'


  const clearPlaylist = () => {
    setPlaylist([])
    setPlayListIndex(0)
    setIsPlaylistOpen(false)
  }

  useEffect(() => {
    let progressUpdater
    // Stop previous item from playing:
    if (playControl) {
      playControl.off()
      playControl.unload()
      console.log('UNLOADED!!')
    }
    // playControl && playControl.off()
    // playControl && playControl.unload()
    console.log('item', item, 'playControl', playControl)
    storage.refFromURL(item.fileGS).getDownloadURL().then(async url => {
      console.log('got url', url)
      // const playControl = new Howl({
      const playControl = new Howl({
        src: await storage.refFromURL(url).getDownloadURL(),
        autoplay: true,
        onplay: () => {
          setIsPlaying(true)
          const dur = playControl.duration()
          progressUpdater = setInterval(() => {
              const seconds = playControl.seek()
              setProgressSecondsFormatted(duration(seconds * 1000).format('hh:mm:ss', {
                stopTrim: 'm'
            }))
              setProgress(seconds / dur)
          }, 250)
        },
        onpause: () => {
          setIsPlaying(false)
          clearInterval(progressUpdater)
        },
        onend: () => {
          skip()
          //TODO: Skip above is using old values, need to get from dashboard
          // as a hack, maybe call on parent to skip and that will read new values?
          console.log('sipping11', playlist.length)
          // PLaylist length only 1 if never skipped song before, if skipped by hand, then it works
          // clearInterval(progressUpdater)
        },
        onload: () => {
          setAudioPlayerItemDurationFormatted(duration(playControl.duration() * 1000).format('hh:mm:ss', {
            stopTrim: 'm'
        }))
        }
    })
    setPlayControl(playControl)
  })
  return () => {
    clearInterval(progressUpdater)
  }
}, [item])

    const play = () => {
      playControl.play()
    }
    
    const pause = () => {
      playControl.pause()
    }

    const togglePlay = () => {
        isPlaying ? pause() : play()
    }

    const togglePlaylist = () => {
        setIsPlaylistOpen(!isPlaylistOpen)
    }

    const previous = () => {
      const newIndex = playListIndex - 1
      pause()
      playControl.unload()
      // setItem(playlist[newIndex])
      // setPlayListIndex(newIndex)
      setPlayListItem(newIndex)
    }
    
    const skip = () => {
      playNextPlaylistItem()
      // const newIndex = playListIndex + 1
      // console.log('sipping', playlist.length, 'newIndex', newIndex, props.playlist.length)
      // if (newIndex >= playlist.length) {
      //   return
      // }
      // pause()
      // playControl.unload()
      // setPlayListItem(newIndex)
    }

    const toggleFrequencyControls = () => {
      setIsfrequeinciesControlsOpen(!isfrequeinciesControlsOpen)
    }

    /*TODO:
    - Remove playlist item curently playing
    - add when playlist exists, i.e. add new item to end and play from there
    - play page
    */

    const addSolfeggioSlider = () => {
      const key = uuid()
      setFrequencySliders([ ...frequencySliders, <SolfeggioSlider onDragStart={() => {
        setCompactPlayerDragDisabled(true)
      }} onDragEnd={() => {
        setCompactPlayerDragDisabled(false)
      }}
      onRemove={() => {
        setFrequencySliders(frequencySliders => frequencySliders.filter(s => s.key !== key))
      }} key={key} /> ])
    }

  return (<div className={classes.container}>
    <Card className={classNames(classes.card, isFreeMode ? classes.cardFree : classes.cardTopBar)}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <IconButton className={classes.compactModeButton}
          onClick={toggleCompactPlayerMode}>
            { isFreeMode ? <UnfoldLess /> : <UnfoldMore /> }
          </IconButton>
          <Typography component="h5" variant="h5">
            {item.texts ? item.texts[language].name : item.name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" display="block" noWrap={true} style={{maxWidth: 264}}>
            {item.texts ? item.texts[language].subtext : item.subtext}
          </Typography>
          <Typography>
          </Typography>
        </CardContent>
        <CardActions>
            <IconButton size="small" color="primary" disabled={playlist.length === 0} onClick={togglePlaylist}>
            {/* isPlaylistOpen ? 'Hide' : 'Show' */} 
            {/* isPlaylistOpen ? <ArrowUpward /> : <ArrowDownward /> */}
              <PlaylistPlay />
            </IconButton>
            <IconButton size="small" color="primary">
              <LoopIcon />
            </IconButton>
        </CardActions>
        <div className={classes.controls}>
          <IconButton aria-label="Previous" disabled={playListIndex === 0} onClick={previous}>
            {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
          </IconButton>
            <div className={classes.playAndProgressButton}>
            <CircularProgress className={classes.playAndProgressButtonProgress} value={progress * 100} variant={playControl ? 'static' : 'indeterminate'} />
            <IconButton aria-label="Play/pause" onClick={togglePlay}>{ isPlaying ? <PauseRounded className={classes.playAndProgressButtonPlay} /> : <PlayArrowIcon style={{opacity: playControl ? 1 : .3}} className={classes.playAndProgressButtonPlay} />}</IconButton>
            </div>
          <IconButton aria-label="Next" disabled={playListIndex >= playlist.length - 1} onClick={skip}>
            {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
          </IconButton>
          <Box flexGrow={1}/>
          <Box flexGrow={1} padding={5} justifyContent="center" alignItems="center">
            <Typography>
              {progressSecondsFormatted}/{audioPlayerItemDurationFormatted}
            </Typography>
          </Box>
        </div>
        <Slider value={progress} min={0} max={1} step={0.01} onChange={(evt,value) => {
          if (evt.type === 'mousedown') {
            setCompactPlayerDragDisabled(true)
          }
          if (playControl) {
            setProgress(value)
            playControl.seek(value * playControl.duration())
          }
        }}
        onChangeCommitted={(evt, value) => {
          setCompactPlayerDragDisabled(false)
          if (playControl) {
            setProgress(value)
            playControl.seek(value * playControl.duration())
          }
        }} />
      </div>
      <CardMedia
        className={classes.cover}
        image={item.imgUrl}
        title={item.texts ? item.texts[language].name : item.name}
      >
      <IconButton onClick={toggleFrequencyControls}>
      <ExpandMore className={isfrequeinciesControlsOpen ? classes.frequencyControlsToggleOpen : classes.frequencyControlsToggleClosed} />
      </IconButton>
      </CardMedia>
    </Card>
    <Paper className={isPlaylistOpen ? 'playlist animated slideInDown' : 'playlist animated slideOutUp' }>
      <List>
      {
        playlist.map((itm, idx) => <ListItem key={`pli-${idx}-${itm.id}`}
        className={classes.playlistItem}
        selected={playListIndex === idx}>
            <ListItemAvatar><Avatar alt={itm.texts ? itm.texts[language].name : itm.name} src={itm.imgUrl}/></ListItemAvatar>
            <ListItemText primary={itm.texts ? itm.texts[language].name : itm.name} />
            <ListItemSecondaryAction>
              <IconButton onClick={() => {
                pause()
                playControl.unload()
                // setItem(itm)
                setPlayListItem(idx)
              }}>
                <PlayArrowIcon />
              </IconButton>
              <IconButton onClick={() => {
                //TODO: If current item playing, also pause and skip!!
                setPlaylist(playlist.filter((i,ix)=>ix!==idx))
              }}>
                <Remove />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>)
      }
      <ListItem>
      <ListItemSecondaryAction>
      <IconButton onClick={clearPlaylist}>
        <Clear />
      </IconButton>
      </ListItemSecondaryAction>
      </ListItem>
      </List>
    </Paper>
    <Paper className={isfrequeinciesControlsOpen ? classes.frequencyControlsOpen : classes.frequencyControls} style={ isFreeMode ? null : { height: PLAYER_HEIGHT_TOP_BAR } } >
    <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        className={classes.frequencyControlsGrid}
        // onMouseDown={() => {
        //   setCompactPlayerDragDisabled(false)
        // }}
        // onMouseUp={() => {
        //   //Also on mouse leave?
        //   setCompactPlayerDragDisabled(true)
        // }}
      >
      <Box direction="column">
        <IconButton onClick={addSolfeggioSlider}>
          <AddCircle />
        </IconButton>
      </Box>
      {
        frequencySliders.map(freqSlider => freqSlider)
      }
      {/* <Slider
        orientation="vertical"
        defaultValue={30}
        marks={solfeggioMarks}
        min={396}
        max={862}
        step={null}
        onChange={(evt,value) => {
          if (evt.type === 'mousedown') {
            setCompactPlayerDragDisabled(true)
          }
        }}
        onChangeCommitted={(evt, value) => {
          setCompactPlayerDragDisabled(false)
        }}
      /> */}
      </Grid>
    </Paper>
    </div>
  );
}

CompactAudioPlayer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CompactAudioPlayer);
