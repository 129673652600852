import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

firebase.initializeApp({
    apiKey: "AIzaSyC17zYlAjmdUpmP2KgQeOUJMQaDKpmxtEE",
    authDomain: "myditation-65ded.firebaseapp.com",
    databaseURL: "https://myditation-65ded.firebaseio.com",
    projectId: "myditation-65ded",
    storageBucket: "myditation-65ded.appspot.com",
    messagingSenderId: "82371041115"
})

firebase.firestore().enablePersistence()
.catch(function(err) {
    if (err.code === 'failed-precondition') {
        console.log('MYditation open in several tabs, offline persistence not enables in this tab.')
    } else if (err.code === 'unimplemented') {
        console.log('Offline persistence disabled, please upgrade your browser')
    }
})

const firestore = firebase.firestore()

// firestore.settings({ timestampsInSnapshots: true })
const auth = firebase.auth()
const storage = firebase.storage()

export { firebase, firestore, auth, storage }